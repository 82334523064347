<template>
  <div align="center">
    <v-toolbar dense class="rounded">
      <v-btn icon class="hidden-xs-only" @click="$router.push('/')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>TÉRMINOS Y CONDICIONES</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <div class="mt-3 rounded white pa-3 text-justify">
      <!-- <b>TÉRMINOS Y CONDICIONES DE USO</b>
      <div>
        Los Términos y Condiciones están para regular de forma justa y
        equilibrada nuestra relación con el usuario, redactas de forma
        unilateral por TRACK AND RACES, titular de la página WEB, sin
        posibilidad que sus usuarios tengan capacidad de negociación, dado que
        se trata de un contrato de adhesión; por lo tanto, el usuario podrá
        hacer uso de este sitio WEB una vez se haya registrado y aceptado los
        términos y condiciones de uso de la misma:
      </div>
      <div>
        <br />
      </div>
      <b>QUIENES SOMOS</b>
      <br />
      <u>Identificación de la Empresa titular del Sitio WEB:</u>
      <div>
        El sitio web www.trackandraces.com, es un servicio online, propiedad de
        <b>SYELTECH SOCIEDAD ANÓNIMA CERRADA - SYELTECH S.A.C.</b>, inscrita en
        RR.PP, en fecha 12 de mayo de 2017, bajo el número de Partida Registral
        13875136, con Registro Único de Contribuyentes (RUC) Nº 20602215319,
        domiciliada en Santiago de Surco, Lima –Perú, que a los efectos del
        contenido de estos términos y condiciones de uso, y la denominaremos
        TRACK AND RACE.
      </div>
      <br />
      <b>POSESIÓN DE PROPIEDAD INTELECTUAL Y DERECHOS DE AUTOR</b>
      <div>
        El Servicio y todos los materiales incluidos o transferidos, incluyendo,
        sin limitación, software, imágenes, texto, gráficos, logotipos,
        patentes, marcas registradas, marcas de servicio, derechos de autor,
        fotografías, audio, música y todos los Derechos de Propiedad Intelectual
        relacionados con ellos, son la propiedad exclusiva de TRACK AND RACE.
        Salvo que se indique explícitamente en este documento, no se considerará
        que nada en estos Términos crea una licencia en o bajo ninguno de dichos
        Derechos de Propiedad Intelectual, y el usuario acepta no vender,
        licenciar, alquilar, modificar, distribuir, copiar, reproducir,
        transmitir, exhibir públicamente, realizar públicamente, publicar,
        adaptar, editar o crear trabajos derivados de los mismos.
      </div>
      <div>
        <br />
      </div>
      <b>SERVICIO PRESTADO</b>
      <div>
        En los últimos años, el sector del juego de apuestas vive una auténtica
        revolución tecnológica de la que forma parte el juego online, este se ha
        desarrollado de manera importante no solo en Europa, sino, además, en
        todo el ámbito sudamericano y latinoamericano.
      </div>
      <div>
        En razón de este importante auge, TRACK AND RACES con gran experiencia
        en el mercado de Apuestas Hípicas y Deportivas, incursiona en el juego
        de Apuestas Online, ofreciendo apuestas sobre eventos de carreras de
        Caballos, de Galgos y Carretas, y muy pronto con las Apuestas de Slots,
        sobre eventos de peleas de Gallos, Apuestas deportivas y de los juegos
        cada vez más buscados. El usuario lo puede hacer desde su computadora
        como desde su tablets o Teléfono celular.
      </div>
      <div>
        TRACK AND RACES tiene definidas explícitamente sus reglas y límites de
        apuestas, así como información sobre las promociones vigentes y soporte
        para que puedan apostar con tranquilidad, haciendo su Sitio Web
        confiable y recomendado; ofreciendo además, bonos de bienvenida y de
        recarga.
      </div>
      <div>
        <br />
      </div>
      <b>APLICACIÓN Y ACEPTACIÓN</b>
      <div>
        1.1.- Los presentes Términos y Condiciones regulan el uso de todos los
        servicios de TRACK AND RACES, incluyendo el sitio web
        www.trackandraces.com y todo su contenido. A su vez contiene varios
        documentos como los Términos y Condiciones , Condiciones Generales y
        Reglas de todos los Juegos, la Política de Privacidad, Registro de
        cuentas y Autenticidad de Datos, Depósitos y Pagos, Reclamaciones y
        Promociones; por lo tanto.
        <b>
          AL UTILIZAR EL SITIO WEB DE TRACK AND RACES (INCLUYENDO SU CONTENIDO)
          Y/O SERVICIOS, ESTÁ DE ACUERDO, ACEPTA Y SE ADHIERE A LOS TÉRMINOS Y
          CONDICIONES QUE SE DETALLAN A CONTINUACIÓN. SI NO ESTÁ DE ACUERDO CON
          ESTOS TÉRMINOS Y CONDICIONES O NO DESEA ESTAR OBLIGADO POR ELLOS, NO
          UTILICE EL SITIO WEB, O LOS SERVICIOS DE TRACK AND RACES.
        </b>
      </div>
      <br />
      <div>
        1.2.- Reserva del derecho de cambiar de oferta: TRACK AND RACES puede
        sin aviso previo cambiar los servicios, dejar de proporcionar los
        servicios o cualquier característica de los servicios que ofrecemos; o
        crear límites para los servicios. Podemos suspender de manera permanente
        o temporal los servicios sin previo aviso ni responsabilidad por
        cualquier motivo, o sin ningún motivo.
      </div>
      <br />
      <div>
        1.3.- Reserva del derecho de aceptar el usuario: TRACK AND RACES se
        reserva el derecho de suspender o rechazar sin previo aviso a cualquier
        usuario que sea sospechoso de: movimientos y/o retiros inusuales en su
        cuenta de apuestas; que tenga cuentas duplicadas; de piratería
        informática o manipulación fraudulenta, participándole de la decisión de
        suspensión o rechazo, través de cualesquiera de los medios
        proporcionados por el usuario al momento de suscribirse.
      </div>
      <br />
      <div>
        1.4.- Forman parte de estos términos y condiciones, tanto las reglas
        generales aplicables a todos los juegos ofrecidos, como las particulares
        de cada juego. Estas Reglas están a disposición del usuario en el Sitio
        Web y las mismas pueden ser modificadas cuando se considere necesario,
        sin previo aviso.
      </div>
      <br />
      <div>
        1.5.- El uso de los servicios por parte del usuario serán únicamente de
        índole personal, en ningún caso con fines comerciales.
      </div>
      <br />
      <div>
        1.6.- Para acceder a nuestro Sitio Web y/o recibir nuestros servicios,
        debes tener al menos dieciocho (18) años de edad, o la mayoría de edad
        legal, el derecho y la libertad para participar en estos Términos como
        un acuerdo vinculante. No tienen permitido utilizar este sitio Web y/o
        recibir servicios, si hacerlo está prohibido en tu país o en virtud de
        cualquier Ley o regulación aplicable a tu caso.
      </div>
      <br />
      <div>
        1.7.- Los términos y condiciones se aplicaran a todo contenido como
        textos, imágenes, archivos, comentarios, etc., enviados o publicados en
        nuestro Sitio Web por el usuario a través de mensajes o blogs del
        usuario, y que atente nuestras políticas y términos; pudiendo ser
        rechazado o eliminado el contenido del usuario, la suspensión del uso de
        los servicios por parte del usuario o la aplicación de cualquier otra
        medida que consideremos necesaria para mantener el uso debido de los
        servicios y su página.
      </div>
      <br />
      <div>
        1.8.- Nos reservamos el derecho de modificar estos términos de vez en
        cuando a nuestra entera discreción. Por lo tanto, el Usuario Apostador
        debe revisar estas páginas periódicamente. Cuando cambiemos los Términos
        de una manera material, notificaremos al Usuario Apostador que se han
        realizado cambios importantes en los Términos. El uso continuado del
        Sitio web o nuestro servicio por parte del Usuario Apostador después de
        dicho cambio constituye tu aceptación de los nuevos Términos. Si el
        Usuario Apostador no acepta alguno de estos términos o cualquier versión
        futura de los Términos, no use o acceda (o continúe accediendo) a la
        página web o al servicio.
      </div>
      <div>
        <br />
      </div>
      <b>REGISTRO DE LA CUENTA Y AUTENTICIDAD DE DATOS</b>
      <div>
        1.- Para poder usar nuestros servicios, el apostador primero debe abrir
        una Cuenta de Apostador, registrando sus datos en nuestra página Web.
        Como parte del proceso de registro, deberá aceptar estas Condiciones de
        Uso, Reglamentos y Politicas de Privacidad, y debe tener capacidad legal
        de aceparlas.
      </div>
      <br />
      <div>
        2.- Debe ser mayor de 18 años o más para poder utilizar nuestros
        servicios, abrir una Cuenta de Apostador, usted declara tiene 18 años o
        más.
      </div>
      <br />
      <div>3.- Puede abrir una sola cuenta de apostador individual.</div>
      <br />
      <div>
        4.- Puede abrir una cuenta de Apuestas, únicamente si es legal en su
        país de Residencia. Al abrir la cuenta de Apuestas, usted declara y nos
        garantiza que la apertura de la cuenta de Apuestas, por su parte no
        infringe ninguna ley ni reglamentación que se aplique a usted. Será
        responsable de cualquier pérdida que incurramos en relación con su
        incumplimiento de esta sección.
      </div>
      <br />
      <div>
        5.- Toda la información que nos proporcione durante el proceso de
        registro, o en cualquier momento posterior, deberá ser precisa,
        verdadera y verificable. Para abrir su cuenta deberá proporcionar su
        nombre completo, fecha de nacimiento, dirección, país de residencia,
        dirección de correo electrónico, número telefónico. Su identidad será
        validada, en caso de no lograrlo se le solicitara que nos proporcione
        información o documentación para tal fin. En caso de que la información
        suministrada sea falsa o de requerirle información y documentación para
        validar su identidad y se niegue a ello, su cuenta podrá ser invalidada.
      </div>
      <br />
      <div>
        6.- Debe asegurarse que la información registrada en su Cuenta de
        Apuestas sea siempre correcta y esté actualizada, y que los datos
        ingresados al registrar la cuenta de apuestas, coincida con su identidad
        legal y coincida con los datos de la cuenta o cuentas que utilizara para
        depositar o recibir dinero.
      </div>
      <br />
      <div>
        7.- Para verificar su identidad, nos reservamos el derecho de utilizar
        los servicios de terceros para autenticar la información de la identidad
        y la cuenta, y para llevar a cabo todas las comprobaciones requeridas
        necesarias en materia de posibles fraudes o para garantizar el
        cumplimiento de nuestras condiciones y reglamentos.
      </div>
      <br />
      <div>
        8.- CUENTA Y CONTRASEÑA: Cuando el Usuario utiliza el Sitio Web, debe
        acceder a su cuenta de apuestas con la dirección de correo electrónico y
        contraseña. Es responsabilidad exclusiva del Usuario:
      </div>
      <br />
      <div>
        a) Mantener la confidencialidad de la dirección de correo electrónico y
        contraseña, siendo el único responsable por todo daño, perjuicio, lesión
        o detrimento que del incumplimiento de esta obligación de
        confidencialidad se genere por cualquier causa.
      </div>
      <br />
      <div>
        b) La Cuenta es personal, única e intransferible, y está prohibido que
        un mismo Usuario Registrado registre o posea más de una Cuenta. En caso
        que se detecte distintas Cuentas que contengan datos coincidentes o
        relacionados, podrá cancelarlas, suspenderlas o inhabilitarlas, a su
        sola discreción, siendo el mismo motivo suficiente para dar de baja al
        Usuario Registrado, incluso en su primera Cuenta.
      </div>
      <br />
      <div>
        c) El Usuario Registrado será responsable por todas las operaciones
        efectuadas desde su Cuenta, pues el acceso a la misma está restringido
        al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del
        Usuario y cuya confidencialidad es de su exclusiva responsabilidad;
      </div>
      <br />
      <div>d) Actualizar y comprobar frecuentemente su contraseña; y</div>
      <br />
      <div>
        c) Se podrá desactivar la cuenta o contraseña elegida por el usuario o
        por nosotros en caso de determinar que ha incumplido con cualquiera de
        las disposiciones establecidas de estos términos y condiciones; y
      </div>
      <br />
      <div>
        d) En caso de olvidar el usuario o contraseña la misma podría ser
        recuperada a través de las distintas opciones disponibles en la página a
        fin de recuperar dicha información o cambiar la misma.
      </div>
      <div>
        <br />
      </div>
      <b>POLÍTICA DE PRIVACIDAD</b>
      <div>
        <b>TRACK AND RACE</b> procesará toda la información personal y de la
        tarjeta de crédito o débito u otro método de pago, que el Usuario
        introduzca o facilite a través de nuestro Sitio Web de conformidad con
        la Política de Privacidad del Sitio Web.
      </div>
      <div>
        <br />
      </div>
      <b>ACEPTACIÓN DE CONDICIONES</b>
      <div>
        El Usuario al aceptar que ha Leído las condiciones de Uso al momento de
        registrarse, o al dar al clic en el botón Regístrate en el Sitio Web, se
        obliga y acepta que el incumplimiento de cualesquiera de las condiciones
        de uso aquí establecidas, puede acarrear la suspensión o cierre temporal
        o definitivo de la Cuenta de Apuestas, la retención o confiscación de
        depósitos, así como las acciones legales contra el usuario, según sea el
        caso y sea procedente conforme se especifica en las nuestras condiciones
        de uso. Al aceptar estas condiciones al registrarse, no podrá cancelar
        la cuenta.
      </div>
      <div>
        <br />
      </div>
      <b>LIMITES DE DEPÓSITO</b>
      <div>
        Al Usuario de la Cuenta de Apuestas, se le permite fijar límite de la
        cantidad de dinero que desea depositar, ya sea, diario, semanal o
        mensual. Puede asimismo, a su conveniencia restringir su límite, lo cual
        será de aplicación inmediata. Si desea liberar el límite de su depósito,
        esta acción tardará aproximadamente 24 horas para que se haga efectivo.
        Es importante que si tiene dudas en relación a la aplicación de los
        límites, establezca contacto con el Equipo de Atención al usuario.
      </div>
      <div>
        <br />
      </div>
      <b>DEPÓSITOS, PAGOS Y RETIROS</b>
      <br />
      <br />
      <b>DEPÓSITOS</b>
      <div>
        El Usuario Apostador para obtener un saldo y poder realizar apuestas
        debe hacer un depósito o transferencia a las cuentas de banco
        establecidas en el Sitio Web, según el país y tipo de moneda.
      </div>
      <div>
        El Usuario Apostador tiene la opción de tener varias carteras en su
        CUENTA DE USUARIO, según el tipo de moneda. Actualmente nuestro Sitio
        Web ofrece diferentes monedas, las cuales estarán disponibles en su
        CUENTA.
      </div>
      <div>
        El usuario Apostador una vez realizado el depósito o transferencia, debe
        ingresar a nuestro Sitio Web y a través de su CUENTA DE USUARIO
        registrar el número de depósito o transferencia según el caso, y debe
        esperar nuestra confirmación. Una vez verificado la disponibilidad del
        monto depositado o transferido por el Usuario Apostador, se le consigna
        dicho monto en la CUENTA DE USUARIO según el tipo de moneda utilizada.
        El Usuario debe considerar al momento de depositar o transferir si es de
        un banco distinto en razón del tiempo para que se haga efectivo y estar
        disponible en la CUENTA DE USUARIO.
      </div>
      <div>
        <br />
      </div>
      <b>RETIROS</b>
      <div>
        El Usuario para realizar un retiro de saldo debe ingresar al Sitio Web,
        y a través de su CUENTA DE USUARIO solicitar el retiro en la opción de
        “SOLICITAR PAGO” colocando el monto a retirar. El Administrador de la
        CUENTA DE USUARIO recibirá la información de la solicitud de retiro y se
        realizará una transferencia desde cualquiera de nuestras cuentas a la
        cuenta bancaria registrada en el perfil del Usuario Apostador, y siempre
        y cuando sea el titular de la cuenta. Por ningún motivo se procesará
        solicitudes de Retiros a cuentas que no estén registradas a nombre del
        Usuario Apostador.
      </div>
      <div>
        Las transferencias realizadas a un mismo banco se harán efectivas el
        mismo día, pero cuando estas se hagan a otros bancos estas podrían
        tardar para que se hagan efectivas un lapso de tiempo mayor de hasta
        Setenta y Dos (72) horas, después de realizarla, considerando que si se
        trata excepcionalmente de días feriados y/o bancarios podrían tardar más
        de Setenta y Dos (72) horas, para que se haga efectivo.
      </div>
      <div>
        <br />
      </div>
      <b>PAGO</b>
      <div>
        Los pagos se efectuarán a la mayor brevedad posible una vez procesada la
        solicitud de retiro, puede que pueda producirse retrasos por la
        verificación y revisión de seguridad.
      </div>
      <div>
        Igualmente puede que se retengan el pago en aplicación de alguna
        disposición aquí establecida en los acuerdos.
      </div>
      <div>
        La transferencia bancaria se hará en la cuenta del usuario en un plazo
        de veinticuatros (24) horas.
      </div>
      <div>
        TRACK AND RACE se reserva el derecho de retener cualquier importe de sus
        ganancias para cumplir alguna ley vigente.
      </div>
      <div>
        El usuario Apostador está obligado y será responsable de cumplir con el
        pago de cualquier impuesto relacionado con las ganancias obtenidas.
      </div>
      <div>
        <br />
      </div>
      <b>PROMOCIONES</b>
      <div>
        TRACK AND RACE a fin de incentivar a sus usuarios, podrá semanalmente
        ofrecer diferentes promociones, las cuales se hará del conocimiento al
        público en el sitio Web en la sección PROMOCIONES, donde se establecerá
        a quienes van dirigidos la promoción, por cuanto tiempo será ésta y los
        términos y condiciones de participación de sus usuarios de cada una de
        ellas.
      </div>
      <div>
        TRACK AND RACE se reserva el derecho de modificar, suspender o retirar
        las promociones sin previo aviso.
      </div>
      <div>
        <br />
      </div>
      <b>LIMITACIÓN DE RESPONSABILIDAD</b>
      <div>
        El Usuario de la Cuenta de Apuestas, declara que está en pleno
        conocimiento y acepta que TRACK AND RACE, sus proveedores y
        licenciatarios, en ningún caso será responsable ante el usuario o
        tercero por daños directos e indirectos, punitivos, incidentales,
        especiales, consecuentes o ejemplares, que el Usuario pueda sufrir,
        indistintamente sea cual fuere la causa que dio origen al daño,
        incluidos, entre otros, daños por pérdida de beneficios, ganancias,
        ingresos, buena voluntad, perdida de datos u otras pérdidas intangibles,
        que surjan de o estén relacionadas con el uso o la imposibilidad de
        utilizar el servicio.
      </div>
      <div>
        TRACK AND RACE no será responsable por cualquier perdida que pueda
        sufrir como consecuencia por cambiar, limitar o dejar de proporcionar
        los servicios o cualquier característica de los servicios que ofrecemos;
        b.-
      </div>
      <div>
        TRACK AND RACE no asume responsabilidad alguna por: 1.- errores o
        inexactitudes de contenido; 2.- lesiones personales o daños a la
        propiedad, de cualquier naturaleza que sean, como resultado del acceso o
        uso por parte del Usuario de nuestro servicio,( No se responsabilizará
        por ningún daño causado a su equipo informático o a otros bienes, ni de
        virus que pudieran infectarlos, como consecuencia de su acceso al sitio,
        a su uso o navegación, o a la descarga de cualquier software o material
        desde el sitio) ; y 3.- cualquier acceso no autorizado o uso de nuestros
        servidores seguros y / o toda la información personal almacenada en los
        mismos.
      </div>
      <div>
        <br />
      </div>
      <b>RECLAMACIONES O DISPUTAS</b>
      <div>
        Cualquier disputa que surja en relación a estos términos de uso pueden
        ser referidos a la Comisión de Juego en concordancia con las
        disposiciones pertinentes del Reglamento.
      </div>
      <div>
        Si el Usuario Apostador tiene dudas o reclamos con respecto al uso del
        Sitio Web, incluyendo cualquier duda con respecto al material que
        aparece en el Sitio Web, deberá:
      </div>
      <div>
        En primer lugar, contacte a soporte@trackandraces.com, donde un agente
        de nuestro equipo de Atención al Usuario se va a encargar de su
        solicitud.
      </div>
      <div>
        En segundo lugar, si su queja o reclamo no pudo ser resuelta con
        satisfacción por nuestro personal de Atención al Cliente, usted puede
        solicitar por escrito a sugerencias@trackandraces.com, que su queja o
        reclamo sea atendida por un Supervisor de Atención al Cliente.
      </div>
      <div>
        <br />
      </div>
      <b>EMAILS DE PROMOCIONES Y CONTENIDO</b>
      <div>
        Acepta recibir de vez en cuando nuestros mensajes y materiales de
        promoción, por correo postal, correo electrónico o cualquier otro
        formulario de contacto que nos proporciones (incluido tu número de
        teléfono para llamadas o mensajes de texto). Si no deseas recibir dichos
        materiales o avisos de promociones, simplemente avísanos en cualquier
        momento.
      </div>
      <div>
        <br />
      </div>
      <b>JURISDICCIÓN APLICABLE</b>
      <div>
        Estos términos y condiciones se regirán por lo interpretado y analizado
        en conformidad y de acuerdo con las leyes del Estado del Perú.
      </div>
      <div>
        <br />
      </div>
 -->
      <b>CONDICIONES GENERALES DE TODOS LOS JUEGOS</b>
      <br />
      <b>SOBRE LAS APUESTAS</b>
      <div>
        1.1.- Todas las reglas, políticas y dividendos publicados en nuestro
        Sitio Web están sujetos a cambios sin previo aviso, de igual forma, los
        límites mínimos y máximos están sujetos a cambios sin previo aviso.
      </div>
      <br />
      <div>
        1.2.- TRACK AND RACES se reserva el derecho de aceptar, limitar o
        rechazar cualquier apuesta antes de realizarla, incluso, podrá ser
        anulada sin previo aviso a consideración de TRACK AND RACES, si una vez
        realizada la apuesta y haya dado inicio el evento, y esta fue realizada
        con el fin de engañar, defraudar, timar, manipular o dañar nuestras
        transacciones comerciales.
      </div>
      <br />
      <div>
        1.3.- Los dividendos correspondientes a las apuestas procesadas de
        acuerdo con el orden oficial definitivo de llegada de cada competencia,
        se pagarán de conformidad hasta los límites de conformidad con lo
        previsto en las Reglas respectivas de cada juego fijadas por TRACK AND
        RACES.
      </div>
      <br />
      <div>
        1.3.1.- CARRERAS DE CABALLOS: Se tomaran como resultados finales para la
        premiación de los eventos la información oficial de cada uno de los
        Hipódromos.
      </div>
      <br />
      <div>
        El tiempo para la premiación efectiva de los boletos será automático e
        inmediato, una vez los resultados oficiales sean publicados.
      </div>
      <br />
      <div>
        Para eventos suspendidos el monto correspondiente al boleto de la
        apuesta será reintegrado una vez la información oficial sea publicada.
      </div>
      <br />
      <div>
        1.3.2.- CARRERAS DE GALGOS: Se tomaran como resultados finales para la
        premiación de los eventos la información oficial de cada uno de los
        Galgódromos.
      </div>
      <br />
      <div>
        El tiempo para la premiación efectiva de los boletos será automático e
        inmediato, una vez los resultados oficiales sean publicados.
      </div>
      <br />
      <div>
        Para eventos suspendidos el monto correspondiente al boleto de la
        apuesta será reintegrado una vez la información oficial sea publicada.
      </div>
      <br />
      <div>1.3.3.- DEPORTES EN VIVO:</div>
      <br />
      <div>
        1.3.4.- APUESTAS VIRTUALES: Conocida como Deportes Virtuales o Virtual
        Sports. Modalidad de juego donde se apuesta a los deportes de siempre,
        pero en eventos virtuales en competiciones generadas por Ordenador. Los
        resultados de cada evento son expuestos en la plataforma y visibles al
        usuario.
      </div>
      <br />
      <div>
        1.4.- El monto de la apuesta, al igual que los dividendos se expresarán
        en Unidades.
      </div>
      <br />
      <div>
        1.5.- Todas las apuestas deberán ser realizadas antes de que inicie el
        evento, cualquier apuesta realizada después del inicio del evento será
        invalidada.
      </div>
      <br />
      <div>
        1.6.- Los dividendos solo serán acreditados en la cuenta de apuestas del
        usuario ganador, si se acredito dividendos en una apuesta por error, el
        usuario de ésta deberá notificar de inmediato a fin de que se ajuste el
        reembolso. En caso de que el usuario no lo reembolse a TRACK AND RACES,
        procederá a retirarlo automáticamente del saldo de la CUENTA DEL
        USUARIO.
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <b>DE LAS APUESTAS DE CABALLOS Y GALGOS</b>
      <br />
      <b>CLASIFICACIÓN DE LOS HIPÓDROMOS Y/O EVENTOS</b>
      <div>
        <br />
      </div>
      <table class="tabl">
        <thead>
          <tr>
            <th>CLASE</th>
            <th>SEGURIDAD</th>
            <th>DESCRIPCIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <h1>A</h1>
            </td>
            <td class="text-center">
              <h3>Seguros</h3>
            </td>
            <td class="text-justify">
              Son hipódromos y/o eventos hípicos que cuentan con una firme
              estructura y alta reputación, en virtud de que tanto sus oficiales
              de carreras como la Comisión de Carreras ejercen un estricto
              control en la operación de los mismos.
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <h1>B</h1>
            </td>
            <td class="text-center">
              <h3>Inseguros</h3>
            </td>
            <td class="text-justify">
              Son hipódromos y/o eventos hípicos que manejan un mínimo de un
              millón de dólares promedio diario, lo que dificulta la
              manipulación de pools. Sin embargo, consideramos que las
              Comisiones de Carreras de sus estados no son tan sofisticadas para
              considerarse seguros.
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <h1>C</h1>
            </td>
            <td class="text-center">
              <h3>Riesgosos</h3>
            </td>
            <td class="text-justify">
              Son hipódromos y/o eventos hípicos de los cuales se decide tomar
              apuestas porque se consideran muy populares o manejan menos de un
              millón de dólares, y se toman para proporcionar servicio a la
              clientela. Sin embargo, se consideran no muy transparentes.
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <h1>D</h1>
            </td>
            <td class="text-center">
              <h3>Muy Riesgosos</h3>
            </td>
            <td class="text-justify">
              Son hipódromos, eventos hípicos y/o jai alais de los cuales se
              decide tomar apuestas porque se consideran muy populares y se
              toman para proporcionar servicio a la clientela. Sin embargo, se
              consideran pocos transparentes.
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <br />
      </div>
      <b
        >CLASIFICACIÓN DE LOS HIPÓDROMOS Y EVENTOS A LOS FINES DE LOS JUEGOS Y/O
        APUESTAS</b
      >
      <div>
        <br />
      </div>
      <table class="tabl">
        <thead>
          <tr>
            <th>CLASE</th>
            <th>HIPÓDROMO</th>
            <th>UBICACIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="16" class="text-center">
              <h1>A</h1>
            </td>
            <td class="text-left">
              <b>AQUEDUCT</b>
            </td>
            <td class="text-justify">
              Aqueduct Racetrack 110-00 Rockaway Blvd. Jamaica NY 11420
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>BELMONT PARK</b>
            </td>
            <td class="text-center">
              <h3></h3>
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>BELMONT STAKES</b>
            </td>

            <td class="text-justify">Cup</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>BETFAIR HOLLYWOOD PARK</b>
            </td>
            <td class="text-justify">
              1050 S. Prairie Ave. Inglewood, CA 90301 California. USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>BREEDER’S CUP</b>
            </td>
            <td class="text-justify">Cup</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>DEL MAR</b>
            </td>
            <td class="text-justify">
              2260 Jimmy Durante Boulevard Del Mar, CA 92014, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>DUBAI WORLD CUP</b>
            </td>
            <td class="text-justify">Cup</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>GULFSTREAM</b>
            </td>
            <td class="text-justify">
              901 S Federal Hwy Hallandale Beach, FL 33009, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>HONG KONG</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>KENTUCKY DERBY</b>
            </td>
            <td class="text-justify">Cup</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>PREAKNESS</b>
            </td>
            <td class="text-justify">Cup</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>SANTA ANITA</b>
            </td>
            <td class="text-justify">
              Santa Anita Park 285 W Huntington Drive Arcadia CA, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>PIMLICO</b>
            </td>
            <td class="text-justify">
              CONDICIÓN ESPECIAL CATEGORÍA “A”
              <br />“SEGUNDA TRIPLE CORONA”
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>CHURCHILL DOWNS</b>
            </td>
            <td class="text-justify">
              700 Central Avenue, Louisville, KY, USA
              <br />CONDICIÓN ESPECIAL CATEGORÍA “A” <br />“PRIMERA TRIPLE
              CORONA”
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>KEENELAND</b>
            </td>
            <td class="text-justify">
              4201 Versailles Rd, Lexington, KY 40510.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>SARATOGA</b>
            </td>
            <td class="text-justify">
              267 Union Avenue Saratoga Springs, NY 12866, USA.
            </td>
          </tr>
          <tr>
            <td rowspan="12" class="text-center">
              <h1>B</h1>
            </td>
            <td class="text-left">
              <b>AUSTRALIA</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>ARLINGTON</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>ATLANTIC CITY</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>HIALEAH</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>FAIRGROUNDS</b>
            </td>
            <td class="text-justify">Fair Dr, Fredericksburg, TX 78624.</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>FAIRPLEX</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>LONGACRES</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>MONMOUTH PARK</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>GARDEN STATE</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>MEADOWLANDS</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>MEADOWLANDS HARNESS</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>PIMLICO</b>
            </td>
            <td class="text-justify">
              CONDICIÓN ESPECIAL CATEGORÍA “A”
              <br />“SEGUNDA TRIPLE CORONA”
            </td>
          </tr>
          <tr>
            <td rowspan="43" class="text-center">
              <h1>C</h1>
            </td>
            <td class="text-left">
              <b>CHARLES TOWNS</b>
            </td>
            <td class="text-justify">
              750 Hollywood Drive Charles Town, WV 25414, USA
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>CHURCHILL DOWNS</b>
            </td>
            <td class="text-justify">
              700 Central Avenue, Louisville, KY, USA
              <br />CONDICION ESPECIAL CATEGORIA “A” <br />“PRIMERA TRIPLE
              CORONA”
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>DELAWARE PARK</b>
            </td>
            <td class="text-justify">
              Delaware Park Boulevard Wilmington, DE 19804, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>DELTA DOWN</b>
            </td>
            <td class="text-justify">
              2717 Delta Downs Dr Vinton, LA 70668, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>ELLIS PARK</b>
            </td>
            <td class="text-justify">
              ELLIS PARK, 3300 U.S 41, HENDERSON, KY.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>EVANGELINE DOWNS</b>
            </td>
            <td class="text-justify">
              2235 Creswell Lane Extension Opelousas, LA 70570, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>FINGER LAKES</b>
            </td>
            <td class="text-justify">
              5857 New York 96 Farmington, NY 14425, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>GOLDEN GATE FIELDS</b>
            </td>
            <td class="text-justify">
              1100 Eastshore Frontage Road Berkeley, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>INDIANA DOWNS</b>
            </td>
            <td class="text-justify">
              4200 Michigan Road, Shelbyville, IN 46176. State: Indiana. USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>LAUREL PARK</b>
            </td>
            <td class="text-justify">
              198 Laurel Race Track Rd, Laurel, MD 20724, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>LOS ALAMITOS</b>
            </td>
            <td class="text-justify">
              4961 Katella Avenue Los Alamitos, CA 90720, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>LOUISIANA DOWNS</b>
            </td>
            <td class="text-justify">
              8000 E Texas St, Bossier City, LA. USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>PENN NATIONAL</b>
            </td>
            <td class="text-justify">
              777 Hollywood Blvd Grantville, PA, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>ARAPAHOE PARK</b>
            </td>
            <td class="text-justify">Qunci Av, Aurora, CO 80016, USA.</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>BALMORAL</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>BAY MEADOWS</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>EMERALD DOWNS</b>
            </td>
            <td class="text-justify">
              2300 Emerald Downs Drive Auburn, WA 98001, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>F FRESNO</b>
            </td>
            <td class="text-justify">
              1121 S. Chance Avenue Fresno, CA 93702.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>F SAN MATEO EN BAY</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>MEADOWS</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>F PLEASANTON</b>
            </td>
            <td class="text-justify">
              1121 S. Chance Avenue Fresno, CA 93702.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>F SACRAMENTO</b>
            </td>
            <td class="text-justify">
              1600 Exposition Blvd. Sacramento, CA 95815.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>F SANTA ROSA</b>
            </td>
            <td class="text-justify">
              1350 BENNETT VALLEY ROAD - SANTA ROSA, CA 95404.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>F STOCKTON</b>
            </td>
            <td class="text-justify">
              1121 S. Chance Avenue Fresno, CA 93702.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>F VALLEJO</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>FAIRMOUNT PARK</b>
            </td>
            <td class="text-justify">
              9301 Collinsville Rd Collinsville, IL 62234,USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>FORT ERIE</b>
            </td>
            <td class="text-justify">
              230 Catherine, Fort Erie, ON L2A 2K9, Canada.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>HAWTHORNE</b>
            </td>
            <td class="text-justify">3501 S Laramie Ave, Cicero, IL 60804.</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>LONE STAR PARK</b>
            </td>
            <td class="text-justify">
              1000 Lone Star Parkway Grand Prairie, TX 75050, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>MAYWOOD PARK HARNESS</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>MOUNTAINEER</b>
            </td>
            <td class="text-justify">1420 Mountaineer Circle.</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>PRAIRIE MEADOWS</b>
            </td>
            <td class="text-justify">
              1 Prairie Meadows Drive Altoona, IA 50009, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>RAYNHAM PARK</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>REMINGTON PARK</b>
            </td>
            <td class="text-justify">
              1 Remington Place Oklahoma City, OK 73111, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>RETAMA PARK</b>
            </td>
            <td class="text-justify">1 Retama Pkwy, Selma, TX 78154.</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>SAM HOUSTON PARK</b>
            </td>
            <td class="text-justify">
              7575 North Sam Houston Pkwy W, Houston, TX 77064.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>SPORTSMAN’S PARK</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-left">
              <b>SUFFOLK DOWNS</b>
            </td>
            <td class="text-justify">111 Waldemar Ave, Boston, MA 02128.</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>THISTLEDOWN</b>
            </td>
            <td class="text-justify">
              21501 Emery Road Cleveland, OH 44128, USA.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>TURF PARADISE</b>
            </td>
            <td class="text-justify">1501 W Bell Rd, Phoenix, AZ 85023.</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>TURFWAY PARK</b>
            </td>
            <td class="text-justify">7500 Turfway Rd, Florence, KY 41042.</td>
          </tr>
          <tr>
            <td class="text-left">
              <b>WOODBINE</b>
            </td>
            <td class="text-justify">
              Rexdale Boulevard Toronto, ON M9W 5L2, Canada.
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <b>YAVAPAI DOWNS</b>
            </td>
            <td class="text-justify"></td>
          </tr>
          <tr>
            <td class="text-center" colspan="3">
              <b>
                <u
                  >TODO HIPÓDROMO O EVENTO NO CONTEMPLADO EN ESTAS
                  CLASIFICACIONES SON CONSIDERADOS TIPO “C” Y TENDRÁN LOS
                  LÍMITES DE PAGOS Y PAGOS MÁXIMOS CORRESPONDIENTE AL MISMO.</u
                >
              </b>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <br />
      </div>
      <div>
        <b
          >REGLAS Y LIMITES APLICABLES DE ACUERDO A LA CLASIFICACIÓN DE LOS
          HIPÓDROMOS</b
        >
      </div>
      <br />
      <table class="tabl">
        <thead>
          <tr>
            <th class="text-center">HIPÓDROMOS CLASE “A”</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <table class="tabl">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">Apuestas Derechas</th>
                  </tr>
                </thead>
                <tbody style="background: #dbf4ff;">
                  <tr>
                    <td class="text-center">
                      <b>Ganador</b>
                    </td>
                    <td>
                      El apostador cobrará a Ganador si su ejemplar finalizó
                      oficialmente en primer lugar.
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b>Place</b>
                    </td>
                    <td>
                      El apostador cobrará a Place si su ejemplar finalizó
                      oficialmente en 1º o 2º lugar.
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b>Show</b>
                    </td>
                    <td>
                      El apostador cobrará a Show si su ejemplar finalizó
                      oficialmente en 1º, 2º o 3º lugar.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-left">
              <b>
                <ul>
                  <li>
                    Entries y fields (llaves) corren bajo un solo interés.
                  </li>
                  <li>
                    En una carrera con cuatro (4) o menos ejemplares , no habrá
                    apuesta de GANADOR, PLACE y SHOW, y se reembolsará el
                    Dinero.
                  </li>
                  <li>No hay Limite de pago</li>
                </ul>
              </b>
            </td>
          </tr>
          <tr>
            <td>
              <table class="tabl">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">Exóticas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Quiniela</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de quiniela si selecciona
                      a los ejemplares ganadores del primer y segundo lugar en
                      cualquier orden de llegada.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            No aplica el límite de pago, excepto que el boleto
                            virtual exceda a lo establecido por nosotros como
                            pago máximo, que en la apuesta de QUINIELA es de
                            diez mil unidades (10.000 U.) por boleto virtual,
                            sin importar las veces apostadas en el mismo.
                          </li>
                          <li>
                            Si un field o entry (misma llave) finalizan en
                            primer y segundo lugar, la combinación ganadora será
                            el field y el ejemplar que finalice en tercer lugar.
                          </li>
                          <li>
                            Si su ejemplar es retirado, el dinero de la apuesta
                            será reembolsado.
                          </li>
                          <li>
                            Si hay un entry o field de tres (3) o más
                            ejemplares, no habrá apuesta de quiniela.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará 500 Unidades por cada Unidad apostada
                            en los boletos virtuales con la combinación
                            acertada, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Exacta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de exacta si selecciona a
                      los ejemplares ganadores del primer y segundo lugar en el
                      orden exacto de llegada.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            No aplica el límite de pago, excepto que el boleto
                            virtual exceda a lo establecido por nosotros como
                            pago máximo, que en la apuesta de QUINIELA es de
                            diez mil unidades (10.000 U.) por boleto virtual,
                            sin importar las veces apostadas en el mismo.
                          </li>
                          <li>
                            Si un field o entry (misma llave) termina en primer
                            y segundo lugar, la combinación ganadora será el
                            field y el ejemplar que llegue a tercer lugar.
                          </li>
                          <li>
                            Si su ejemplar es retirado, el dinero de la apuesta
                            será reembolsado, y no habrá pagos de consolación.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará 500 Unidades por cada Unidad apostada
                            en los boletos virtuales con la combinación
                            acertada, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            En una carrera con cuatro (4) o menos ejemplares, no
                            habrá apuesta de EXACTA y será reembolsado el
                            dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Doble Selección (Pick 2)</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de doble selección si
                      selecciona al ejemplar ganador del primer lugar en dos
                      carreras consecutivas designadas con apuesta de doble
                      selección.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            No aplica el límite de pago, excepto que el boleto
                            virtual exceda a lo establecido por nosotros como
                            pago máximo, que en la apuesta de DOBLE SELECCIÓN
                            (PICK 2) es de diez mil unidades (10.000 U.) por
                            boleto virtual, sin importar las veces apostadas en
                            el mismo.
                          </li>
                          <li>
                            Si su ejemplar es retirado de cualquiera de las dos
                            (2) carreras designadas como «doble selección», el
                            dinero de la apuesta será reembolsado, y no habrá
                            pagos de consolación.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará 500 Unidades por cada Unidad apostada
                            en los boletos virtuales con la combinación
                            acertada, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), el
                              dinero de la apuesta será reembolsado.</u
                            >
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Trifecta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Trifecta si selecciona
                      a los ejemplares ganadores del primer, segundo y tercer
                      lugar en el orden exacto de llegada, en las carreras
                      designadas con apuesta de Trifecta.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            No aplica el límite de pago, excepto que el boleto
                            virtual exceda a lo establecido por nosotros como
                            pago máximo, que en la apuesta de TRIFECTA es de
                            quince mil unidades (15.000 U.) por boleto virtual,
                            sin importar las veces apostadas en el mismo.
                          </li>
                          <li>
                            Si en el evento su ejemplar es retirado, el dinero
                            de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de los tres
                            (3) lugares, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los hipódromos
                            de origen, no siendo aplicable por nuestro Sitio Web
                            el pago de este premio.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará 3.000 Unidades por cada Unidad
                            apostada en los boletos virtuales con la combinación
                            acertada, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            En una carrera con cinco (5) o menos ejemplares, no
                            habrá apuesta de TRIFECTA, y será reembolsado el
                            Dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Triple Selección (Pick 3)</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de triple selección si
                      selecciona al ejemplar ganador del primer lugar en tres
                      carreras consecutivas designadas con apuesta de triple
                      selección.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            No aplica el límite de pago, excepto que el boleto
                            virtual exceda a lo establecido por nosotros como
                            pago máximo, que en la apuesta de PICK 3 es de
                            veinte mil unidades (20.000 U.) por boleto virtual,
                            sin importar las veces apostadas en el mismo.
                          </li>
                          <li>
                            Si su ejemplar es retirado del evento, éste será
                            sustituido por el primer ejemplar favorito en el
                            Hipódromo de origen al cierre de la carrera; y en
                            caso, de que el ejemplar favorito resulte ganador,
                            se pagará por el favorito ganador y por cada
                            ejemplar retirado del evento. No habrá reembolsos
                            por los retirados.
                          </li>
                          <li>
                            En caso de que una o más carreras sean canceladas,
                            el dinero de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de las tres
                            (3) carreras, por no haber combinación ganadora,
                            esto equivale a premio de consolación para los
                            hipódromos, no siendo aplicable por el Sitio Web el
                            pago de este premio.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo, no habrá premio de consolación, solo se
                            pagará 3.000 Unidades por cada Unidad apostada en
                            los boletos virtuales con la combinación acertada,
                            siendo igualmente aplicable el pago máximo.
                          </li>
                          <li>No hay acumulados.</li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (problemas de energía
                              eléctrica, cambio de pista, etc.), y el hipódromo
                              de origen pagare con todos los ejemplares
                              participantes, nuestro Sitio Web de apuestas
                              aplicará:</u
                            >
                            <ol>
                              <li>
                                Los resultados de pago del hipódromo de origen;
                              </li>
                              <li>
                                Solo pagará los tickets con la combinación
                                acertada;
                              </li>
                              <li>
                                Pagará los ejemplares adicionales que hayan sido
                                seleccionados por el apostador en la carrera
                                donde hubo la circunstancia o situación no
                                prevista.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Superfecta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Superfecta si
                      selecciona a los ejemplares ganadores del primer, segundo,
                      tercero y cuarto lugar, en el orden exacto de llegada, en
                      carreras designadas con apuesta de Superfecta.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            No aplica el límite de pago, excepto que el boleto
                            virtual exceda a lo establecido por nosotros como
                            pago máximo, que en la apuesta de SUPERFECTA es de
                            veinticinco mil unidades (25.000 U.) por boleto
                            virtual, sin importar las veces apostadas en el
                            mismo.
                          </li>
                          <li>
                            Si en el evento su ejemplar es retirado, el dinero
                            de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de los cuatro
                            (4) lugares, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los hipódromos
                            de origen, no siendo aplicable por nuestro Sitio Web
                            el pago de este premio.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará 4.500 Unidades por cada Unidad
                            apostada en los boletos virtuales con la combinación
                            acertada, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            En una carrera con seis (6) o menos ejemplares, no
                            habrá apuesta de SUPERFECTA, y se reembolsará el
                            dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Pick 4</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Pick 4 si selecciona al
                      ejemplar ganador del primer lugar en cuatro carreras
                      consecutivas designadas con apuesta de pick 4.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            No aplica el límite de pago, excepto que el boleto
                            virtual exceda a lo establecido por nosotros como
                            pago máximo, que en la apuesta de PICK 4 es de
                            treinta mil unidades (30.000 U.) por boleto virtual,
                            sin importar las veces apostadas en el mismo.
                          </li>
                          <li>No habrá reembolsos por los retirados.</li>
                          <li>
                            Si su ejemplar es retirado del evento, éste será
                            sustituido por el primer ejemplar favorito en el
                            Hipódromo de origen al cierre de la carrera; y en
                            caso, de que el ejemplar favorito resulte ganador,
                            se pagará por el favorito ganador y por cada
                            ejemplar retirado del evento.
                          </li>
                          <li>
                            En caso de que una o más carreras sean canceladas,
                            el dinero de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes, en cualquiera de las
                            cuatro (4) carreras por no haber combinación
                            ganadora, esto equivale a premio de consolación para
                            los hipódromos de origen, no siendo aplicable por
                            nuestro Sitio Web el pago de este premio.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará 4.500 Unidades por cada Unidad
                            apostada en los boletos virtuales con la combinación
                            acertada, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>No hay acumulados.</li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (problemas de energía
                              eléctrica, cambio de pista, etc.), y el hipódromo
                              de origen pagare con todos los ejemplares
                              participantes, nuestro Sitio Web de apuestas
                              aplicará:</u
                            >
                            <ol>
                              <li>
                                Los resultados de pago del hipódromo de origen;
                              </li>
                              <li>
                                Solo pagará los tickets con la combinación
                                acertada;
                              </li>
                              <li>
                                Pagará los ejemplares adicionales que hayan sido
                                seleccionados por el apostador en la carrera
                                donde hubo la circunstancia o situación no
                                prevista.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Parlay</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de PARLAY si selecciona a
                      dos o tres ejemplares en diferentes carreras y/o
                      diferentes hipódromos y finalizan ganadores. La apuesta
                      debe ser, ya sea a primer, segundo o tercer lugar, pero
                      todos los ejemplares seleccionados deben estar en el mismo
                      orden de apuesta, y las selecciones deberán finalizar en
                      el orden elegido.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            Los límites de pagos en Parlay se figuran de la
                            siguiente manera:
                            <ul>
                              <li>
                                Primer lugar: 20 unidades por cada unidad
                                apostada.
                              </li>
                              <li>
                                Segundo lugar: 8 unidades por cada unidad
                                apostada.
                              </li>
                              <li>
                                Tercer lugar: 4 unidades por cada unidad
                                apostada.
                              </li>
                            </ul>
                          </li>
                          <li>
                            El límite de pagos es de ciento cincuenta unidades
                            (150 U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual de parlay es de
                            diez mil unidades (10.000 U.).
                          </li>
                          <li>
                            El máximo número de ejemplares en un parlay es de
                            tres (3), y el mínimo dos (2)
                          </li>
                          <li>
                            Si su ejemplar es retirado, un parlay de tres (3) se
                            convierte en un parlay de dos (2), y un parlay de
                            dos (2) se convierte en apuesta derecha
                          </li>
                          <li>Entries y fields corren bajo un solo interés</li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table class="tabl">
        <thead>
          <tr>
            <th class="text-center">HIPÓDROMOS CLASE “B”</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <table class="tabl">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">Apuestas Derechas</th>
                  </tr>
                </thead>
                <tbody style="background: #dbf4ff;">
                  <tr>
                    <td class="text-center">
                      <b>Ganador</b>
                    </td>
                    <td>
                      El apostador cobrará a Ganador si su ejemplar finalizó
                      oficialmente en primer lugar.
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b>Place</b>
                    </td>
                    <td>
                      El apostador cobrará a Place si su ejemplar finalizó
                      oficialmente en 1º o 2º lugar.
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b>Show</b>
                    </td>
                    <td>
                      El apostador cobrará a Show si su ejemplar finalizó
                      oficialmente en 1º, 2º o 3º lugar.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-left">
              <b>
                <ul>
                  <li>
                    Entries y fields (llaves) corren bajo un solo interés.
                  </li>
                  <li>
                    En una carrera con cuatro (4) o menos ejemplares , no habrá
                    apuesta de GANADOR, PLACE y SHOW, y se reembolsará el
                    Dinero.
                  </li>
                  <li>
                    El pago máximo por TICKET es de diez mil unidades
                    (10.000U.), sin importar las veces apostadas en el mismo
                    boleto.
                  </li>
                  <li>
                    Si su ejemplar es retirado, el dinero de la apuesta será
                    reembolsado.
                  </li>
                  <li>
                    El pago se hará de la siguiente manera:
                    <ul>
                      <li>
                        Primer lugar: 30 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Segundo lugar: 12 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Tercer lugar: 6 unidades por cada unidad apostada.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Para carreras de caballos dosañeros, los límites de pago
                    son:
                    <ul>
                      <li>
                        Primer lugar: 30 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Segundo lugar: 12 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Tercer lugar: 6 unidades por cada unidad apostada.
                      </li>
                    </ul>
                  </li>
                </ul>
              </b>
            </td>
          </tr>
          <tr>
            <td>
              <table class="tabl">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">Exóticas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Quiniela</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de quiniela si selecciona
                      a los ejemplares ganadores del primer y segundo lugar en
                      cualquier orden de llegada.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es trescientas unidades (300 U.)
                            por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por Boleto virtual es de diez mil
                            unidades (10.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si un field o entry (misma llave) finaliza en primer
                            y segundo lugar, la combinación ganadora serán el
                            field y el ejemplar que finalice en tercer lugar.
                          </li>
                          <li>
                            Si su ejemplar es retirado, el dinero de la apuesta
                            será reembolsado.
                          </li>
                          <li>
                            Si hay un entry o field de tres (3) o más
                            ejemplares, no habrá apuesta de quiniela.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación acertada en nuestro
                            sitio Web de apuestas, siendo igualmente aplicable
                            el pago máximo.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Exacta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de exacta si selecciona a
                      los ejemplares ganadores del primer y segundo lugar en el
                      orden exacto de llegada.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es trescientas unidades (300 U.)
                            por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de diez mil
                            unidades (10.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si un field o entry termina en primer y segundo
                            lugar, la combinación ganadora será el field y el
                            ejemplar que llegue a tercer lugar.
                          </li>
                          <li>
                            Si su ejemplar es retirado, el dinero de la apuesta
                            será reembolsado.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación acertada en nuestro
                            Sitio Web de apuestas, siendo igualmente aplicable
                            el pago máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En una carrera con cuatro (4) o menos ejemplares, no
                            habrá apuesta de EXACTA y será reembolsado el
                            Dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Doble Selección (Pick 2)</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de doble selección si
                      selecciona al ejemplar ganador del primer lugar en dos
                      carreras consecutivas designadas con apuesta de doble
                      selección.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es trescientas unidades (300 U.)
                            por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de diez mil
                            unidades (10.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si su ejemplar es retirado de cualquiera de las dos
                            (2) carreras designadas como «doble selección», el
                            dinero de la apuesta será reembolsado, y no habrá
                            pagos de consolación.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación acertada en nuestro
                            Sitio Web de apuestas, siendo igualmente aplicable
                            el pago máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), el
                              dinero de la apuesta será reembolsado.</u
                            >
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Trifecta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Trifecta si selecciona
                      a los ejemplares ganadores del primer, segundo y tercer
                      lugar en el orden exacto de llegada, en las carreras
                      designadas con apuesta de Trifecta.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es dos mil quinientas unidades
                            (2.500 U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de quince mil
                            unidades (15.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si en el evento su ejemplar es retirado, el dinero
                            de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de los tres
                            (3) lugares, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los hipódromos
                            de origen, no siendo aplicable el pago de este
                            premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación acertada en nuestro
                            Sitio Web de apuestas, siendo igualmente aplicable
                            el pago máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En una carrera con cinco (5) o menos ejemplares, no
                            habrá apuesta de TRIFECTA, y se reembolsará el
                            dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Triple Selección (Pick 3)</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de triple selección si
                      selecciona al ejemplar ganador del primer lugar en tres
                      carreras consecutivas designadas con apuesta de triple
                      selección.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es dos mil quinientas unidades
                            (2.500 U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de quince mil
                            unidades (15.000U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>No habrá reembolsos por los retirados.</li>
                          <li>
                            Si su ejemplar es retirado del evento, éste será
                            sustituido por el primer ejemplar favorito del
                            Hipódromo de origen al cierre de la carrera; y en
                            caso, de que el ejemplar favorito resulte ganador,
                            se pagará por el favorito ganador y por cada
                            ejemplar retirado del evento.
                          </li>
                          <li>
                            En caso de que una o más carreras sean canceladas,
                            el dinero de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de las tres
                            (3) carreras, por no haber combinación ganadora,
                            esto equivale a premio de consolación para los
                            hipódromos de origen, no siendo aplicable el pago de
                            este premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación acertada en nuestro
                            Sitio Web de apuestas, siendo igualmente aplicable
                            el pago máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>No hay acumulados.</li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), y el
                              hipódromo de origen pagare con todos los
                              ejemplares participantes, nuestro Sitio Web de
                              apuestas aplicará:</u
                            >
                            <ol>
                              <li>
                                Los resultados de pago del hipódromo de origen;
                              </li>
                              <li>
                                Solo pagará los tickets con la combinación
                                acertada;
                              </li>
                              <li>
                                Pagará los ejemplares adicionales que hayan sido
                                seleccionados por el apostador en la carrera
                                donde hubo cambio de pista.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Superfecta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Superfecta si
                      selecciona a los ejemplares ganadores del primer, segundo,
                      tercero y cuarto lugar, en el orden exacto de llegada, en
                      carreras designadas con apuesta de Superfecta.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es de cuatro mil unidades (4.000
                            U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de veinte mil
                            unidades (20.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si en el evento su ejemplar es retirado, el dinero
                            de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de los cuatro
                            (4) lugares, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los hipódromos
                            de origen, no siendo aplicable el pago de este
                            premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación acertada en nuestro
                            Sitio Web de apuestas, siendo igualmente aplicable
                            el pago máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En una carrera con seis (6) o menos ejemplares, no
                            habrá apuesta de SUPERFECTA, y se reembolsará el
                            dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Pick 4</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Pick 4 si selecciona al
                      ejemplar ganador del primer lugar en cuatro carreras
                      consecutivas designadas con apuesta de pick 4.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es cuatro mil unidades (4.000 U.)
                            por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de veinte mil
                            unidades (20.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>No habrá reembolsos por los retirados.</li>
                          <li>
                            Si su ejemplar es retirado del evento, éste será
                            sustituido por el primer ejemplar favorito del
                            hipódromo de origen al cierre de la carrera; y en
                            caso, de que el ejemplar favorito resulte ganador,
                            se pagará por el favorito ganador y por cada
                            ejemplar retirado del evento.
                          </li>
                          <li>
                            En caso de que una o más carreras sean canceladas,
                            el dinero de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de las cuatro
                            carreras, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los hipódromos
                            de origen, no siendo aplicable el pago de este
                            premio en nuestro Sitio Web de apuestas, siendo
                            igualmente aplicable el pago máximo.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación acertada en nuestro
                            Sitio Web de apuestas.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>No hay acumulados.</li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), y el
                              hipódromo de origen pagare con todos los
                              ejemplares participantes, nuestro Sitio Web de
                              apuestas aplicará:</u
                            >
                            <ol>
                              <li>
                                Los resultados de pago del hipódromo de origen;
                              </li>
                              <li>
                                Solo pagará los tickets con la combinación
                                acertada;
                              </li>
                              <li>
                                Pagará los ejemplares adicionales que hayan sido
                                seleccionados por el apostador en la carrera
                                donde hubo cambio de pista.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Parlay</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de PARLAY si selecciona a
                      dos o tres ejemplares en diferentes carreras y/o
                      diferentes hipódromos y finalizan ganadores. La apuesta
                      debe ser, ya sea a primer, segundo o tercer lugar, pero
                      todos los ejemplares seleccionados deben estar en el mismo
                      orden de apuesta, y las selecciones deberán finalizar en
                      el orden elegido.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            Los límites de pagos en Parlay se figuran de la
                            siguiente manera:
                            <ul>
                              <li>
                                Primer lugar: 20 unidades por cada unidad
                                apostada.
                              </li>
                              <li>
                                Segundo lugar: 8 unidades por cada unidad
                                apostada.
                              </li>
                              <li>
                                Tercer lugar: 4 unidades por cada unidad
                                apostada.
                              </li>
                            </ul>
                          </li>
                          <li>
                            El límite de pagos es de ciento cincuenta unidades
                            (150 U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual de parlay es de
                            6.000 Unidades.
                          </li>
                          <li>
                            El máximo número de ejemplares en un parlay es de
                            tres (3), y el mínimo dos (2)
                          </li>
                          <li>
                            Si su ejemplar es retirado, un parlay de tres (3) se
                            convierte en un parlay de dos (2), y un parlay de
                            dos (2) se convierte en apuesta derecha
                          </li>
                          <li>Entries y fields corren bajo un solo interés</li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table class="tabl">
        <thead>
          <tr>
            <th class="text-center">HIPÓDROMOS CLASE “C”</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <table class="tabl">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">Apuestas Derechas</th>
                  </tr>
                </thead>
                <tbody style="background: #dbf4ff;">
                  <tr>
                    <td class="text-center">
                      <b>Ganador</b>
                    </td>
                    <td>
                      El apostador cobrará a Ganador si su ejemplar finalizó
                      oficialmente en primer lugar.
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b>Place</b>
                    </td>
                    <td>
                      El apostador cobrará a Place si su ejemplar finalizó
                      oficialmente en 1º o 2º lugar.
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b>Show</b>
                    </td>
                    <td>
                      El apostador cobrará a Show si su ejemplar finalizó
                      oficialmente en 1º, 2º o 3º lugar.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-left">
              <b>
                <ul>
                  <li>
                    Entries y fields (llaves) corren bajo un solo interés.
                  </li>
                  <li>
                    En una carrera con cuatro (4) o menos ejemplares , no habrá
                    apuesta de GANADOR, PLACE y SHOW, y se reembolsará el
                    Dinero.
                  </li>
                  <li>
                    El pago máximo por TICKET es de diez mil unidades
                    (10.000U.), sin importar las veces apostadas en el mismo
                    boleto.
                  </li>
                  <li>
                    Si su ejemplar es retirado, el dinero de la apuesta será
                    reembolsado.
                  </li>
                  <li>
                    El pago se hará de la siguiente manera:
                    <ul>
                      <li>
                        Primer lugar: 20 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Segundo lugar: 8 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Tercer lugar: 4 unidades por cada unidad apostada.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Para carreras de caballos dosañeros, los límites de pago
                    son:
                    <ul>
                      <li>
                        Primer lugar: 20 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Segundo lugar: 8 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Tercer lugar: 4 unidades por cada unidad apostada.
                      </li>
                    </ul>
                  </li>
                </ul>
              </b>
            </td>
          </tr>
          <tr>
            <td>
              <table class="tabl">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">Exóticas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Quiniela</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de quiniela si selecciona
                      a los ejemplares ganadores del primer y segundo lugar en
                      cualquier orden de llegada.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es ciento cincuenta Unidades (150
                            U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de seis mil
                            unidades (6.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si un field o entry (misma llave) finaliza en primer
                            y segundo lugar, la combinación ganadora será el
                            field y el ejemplar que finalice en tercer lugar.
                          </li>
                          <li>
                            Si su ejemplar es retirado, el dinero de la apuesta
                            será reembolsado.
                          </li>
                          <li>
                            Si hay un entry o field de tres (3) o más
                            ejemplares, no habrá apuesta de quiniela.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación en nuestro Sitio Web de
                            apuestas, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Exacta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de exacta si selecciona a
                      los ejemplares ganadores del primer y segundo lugar en el
                      orden exacto de llegada.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es trescientas unidades (300 U.)
                            por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de seis mil
                            unidades (6.000 U.), sin importar las veces
                            apostadas en el boleto
                          </li>
                          <li>
                            Si un field o entry termina en primer y segundo
                            lugar, la combinación ganadora será el field y el
                            ejemplar que llegue a tercer lugar.
                          </li>
                          <li>
                            Si su ejemplar es retirado, el dinero de la apuesta
                            será reembolsado.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación acertada en nuestro
                            Sitio Web de apuestas, siendo igualmente aplicable
                            el pago máximo.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En una carrera con cuatro (4) o menos ejemplares, no
                            habrá apuesta de EXACTA y será reembolsable el
                            Dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Doble Selección (Pick 2)</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de doble selección si
                      selecciona al ejemplar ganador del primer lugar en dos
                      carreras consecutivas designadas con apuesta de doble
                      selección.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es trescientas unidades (300 U.)
                            por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de seis mil
                            unidades (6.000 U.), sin importar las veces
                            apostadas en el boleto
                          </li>
                          <li>
                            Si su ejemplar es retirado de cualquiera de las dos
                            (2) carreras designadas como «doble selección», el
                            dinero de la apuesta será reembolsado, y no habrá
                            pagos de consolación.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación en nuestro Sitio Web de
                            apuestas, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), el
                              dinero de la apuesta será reembolsado.</u
                            >
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Trifecta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Trifecta si selecciona
                      a los ejemplares ganadores del primer, segundo y tercer
                      lugar en el orden exacto de llegada, en las carreras
                      designadas con apuesta de Trifecta.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es dos mil unidades (2.000 U.) por
                            cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de diez mil
                            unidades (10.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si en el evento su ejemplar es retirado, el dinero
                            de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen pague con todos los
                            ejemplares participantes en cualquiera de los tres
                            (3) lugares, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los hipódromos
                            de origen, no siendo aplicable el pago de este
                            premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación en nuestro Sitio Web de
                            apuestas, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En una carrera con cinco (5) o menos ejemplares, no
                            habrá apuesta de TRIFECTA, y se reembolsará el
                            dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Triple Selección (Pick 3)</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de triple selección si
                      selecciona al ejemplar ganador del primer lugar en tres
                      carreras consecutivas designadas con apuesta de triple
                      selección.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es dos mil unidades (2.000 U.) por
                            cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de diez mil
                            unidades (10.000U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>No habrá reembolsos por los retirados.</li>
                          <li>
                            Si su ejemplar es retirado del evento, éste será
                            sustituido por el primer ejemplar favorito del
                            Hipódromo de origen al cierre de la carrera; y en
                            caso, de que el ejemplar favorito resulte ganador,
                            se pagará por el favorito ganador y por cada
                            ejemplar retirado del evento.
                          </li>
                          <li>
                            En caso de que una o más carreras sean canceladas,
                            el dinero de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo paga con todos los ejemplares
                            participantes en cualquiera de las tres (3)
                            carreras, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los hipódromos
                            de origen, no siendo aplicable el pago de este
                            premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación en nuestro Sitio Web de
                            apuestas, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>No hay acumulados.</li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), y el
                              hipódromo de origen pagare con todos los
                              ejemplares participantes, nuestro Sitio Web de
                              apuestas aplicará:</u
                            >
                            <ol>
                              <li>
                                Los resultados de pago del hipódromo de origen;
                              </li>
                              <li>
                                Solo pagará los tickets con la combinación
                                acertada;
                              </li>
                              <li>
                                Pagará los ejemplares adicionales que hayan sido
                                seleccionados por el apostador en la carrera
                                donde hubo cambio de pista.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Superfecta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Superfecta si
                      selecciona a los ejemplares ganadores del primer, segundo,
                      tercero y cuarto lugar, en el orden exacto de llegada, en
                      carreras designadas con apuesta de Superfecta.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es de tres mil unidades (3.000 U.)
                            por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de diez mil
                            unidades (10.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si en el evento su ejemplar es retirado, el dinero
                            de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de los cuatro
                            (4) lugares, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los hipódromos
                            de origen, no siendo aplicable el pago de este
                            premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación en nuestro sitio Web de
                            apuestas, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En una carrera con seis (6) o menos ejemplares, no
                            habrá apuesta de SUPERFECTA, y se reembolsará el
                            dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Pick 4</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Pick 4 si selecciona al
                      ejemplar ganador del primer lugar en cuatro carreras
                      consecutivas designadas con apuesta de pick 4.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es tres mil unidades (3.000 U.)
                            por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de diez mil
                            unidades (10.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>No habrá reembolsos por los retirados.</li>
                          <li>
                            Si su ejemplar es retirado del evento, éste será
                            sustituido por el primer ejemplar favorito del
                            hipódromo de origen al cierre de la carrera; y en
                            caso, de que el ejemplar favorito resulte ganador,
                            se pagará por el favorito ganador y por cada
                            ejemplar retirado del evento.
                          </li>
                          <li>
                            En caso de que una o más carreras sean canceladas,
                            el dinero de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el hipódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de las cuatro
                            (4) carreras, por no haber combinación ganadora,
                            esto equivale a premio de consolación para los
                            hipódromos de origen, no siendo aplicable el pago de
                            este premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            hipódromo de origen, no habrá premio de consolación,
                            solo se pagará el límite de pago a los boletos
                            virtuales con la combinación en nuestro Sitio Web de
                            apuestas, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>No hay acumulados.</li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), y el
                              hipódromo de origen pagare con todos los
                              ejemplares participantes, nuestro Sitio Web de
                              apuestas aplicará:</u
                            >
                            <ol>
                              <li>
                                Los resultados de pago del hipódromo de origen;
                              </li>
                              <li>
                                Solo pagará los tickets con la combinación
                                acertada;
                              </li>
                              <li>
                                Pagará los ejemplares adicionales que hayan sido
                                seleccionados por el apostador en la carrera
                                donde hubo cambio de pista.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Parlay</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de PARLAY si selecciona a
                      dos o tres ejemplares en diferentes carreras y/o
                      diferentes hipódromos y finalizan ganadores. La apuesta
                      debe ser, ya sea a primer, segundo o tercer lugar, pero
                      todos los ejemplares seleccionados deben estar en el mismo
                      orden de apuesta, y las selecciones deberán finalizar en
                      el orden elegido.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            Los límites de pagos en Parlay se figuran de la
                            siguiente manera:
                            <ul>
                              <li>
                                Primer lugar: 20 unidades por cada unidad
                                apostada.
                              </li>
                              <li>
                                Segundo lugar: 8 unidades por cada unidad
                                apostada.
                              </li>
                              <li>
                                Tercer lugar: 4 unidades por cada unidad
                                apostada.
                              </li>
                            </ul>
                          </li>
                          <li>
                            El límite de pagos es de ciento cincuenta unidades
                            (150 U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual de parlay es de
                            6.000 Unidades.
                          </li>
                          <li>
                            El máximo número de ejemplares en un parlay es de
                            tres (3), y el mínimo dos (2)
                          </li>
                          <li>
                            Si su ejemplar es retirado, un parlay de tres (3) se
                            convierte en un parlay de dos (2), y un parlay de
                            dos (2) se convierte en apuesta derecha
                          </li>
                          <li>Entries y fields corren bajo un solo interés</li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <div>
        <b>REGLAS DE OPERACIÓN PARA LOS GALGODROMOS Y JAI ALAI</b>
      </div>
      <br />
      <table class="tabl">
        <thead>
          <tr>
            <th class="text-center">CLASE “C”</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <table class="tabl">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">Apuestas Derechas</th>
                  </tr>
                </thead>
                <tbody style="background: #dbf4ff;">
                  <tr>
                    <td class="text-center">
                      <b>Ganador</b>
                    </td>
                    <td>
                      El apostador cobrará a Ganador si su ejemplar finalizó
                      oficialmente en primer lugar.
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b>Place</b>
                    </td>
                    <td>
                      El apostador cobrará a Place si su ejemplar finalizó
                      oficialmente en primer o segundo lugar.
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <b>Show</b>
                    </td>
                    <td>
                      El apostador cobrará a Show si su ejemplar finalizó
                      oficialmente en primer, segundo o tercer lugar.
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="text-left">
              <b>
                <ul>
                  <li>
                    Limites:
                    <ul>
                      <li>
                        Primer lugar: 15 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Segundo lugar: 8 unidades por cada unidad apostada.
                      </li>
                      <li>
                        Tercer lugar: 4 unidades por cada unidad apostada.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Entries y fields (llaves) corren bajo un solo interés.
                  </li>
                  <li>
                    Si su ejemplar es retirado, el dinero de la apuesta será
                    reembolsado.
                  </li>
                </ul>
              </b>
            </td>
          </tr>
          <tr>
            <td>
              <table class="tabl">
                <thead>
                  <tr>
                    <th class="text-center" colspan="2">Exóticas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Quiniela</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de quiniela si selecciona
                      a los ejemplares ganadores del primer y segundo lugar en
                      cualquier orden de llegada.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es cien unidades (100 U.) por cada
                            unidad apostada.
                          </li>
                          <li>
                            El pago máximo por Boleto virtual es de un mil
                            unidades (1.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si un field o entry (misma llave) finalizan en
                            primer y segundo lugar, la combinación ganadora
                            serán el field y el ejemplar que finalice en tercer
                            lugar.
                          </li>
                          <li>
                            Si su ejemplar es retirado, el dinero de la apuesta
                            será reembolsado.
                          </li>
                          <li>
                            Si hay un entry o field de 3 o más ejemplares, no
                            habrá apuesta de quiniela.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            Galgódromo de origen, no habrá premio de
                            consolación, solo se pagará el límite de pago a los
                            boletos virtuales con la combinación acertada en
                            nuestro Sitio Web de apuestas, siendo igualmente
                            aplicable el pago máximo.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Exacta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de exacta si selecciona a
                      los ejemplares ganadores del primer y segundo lugar en el
                      orden exacto de llegada.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es cien unidades (100 U.) por cada
                            unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de dos mil
                            unidades (2.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si un field o entry termina en primer y segundo
                            lugar, la combinación ganadora será el field y el
                            ejemplar que llegue a tercer lugar.
                          </li>
                          <li>
                            Si su ejemplar es retirado, el dinero de la apuesta
                            será reembolsado.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            Galgódromo de origen, no habrá premio de
                            consolación, solo se pagará el límite de pago a los
                            boletos virtuales con la combinación acertada en
                            nuestro Sitio Web de apuestas, siendo igualmente
                            aplicable el pago máximo.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Doble Selección (Pick 2)</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de doble selección si
                      selecciona al ejemplar ganador del primer lugar en dos
                      carreras consecutivas designadas con apuesta de doble
                      selección.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es ciento cincuenta unidades (150
                            U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de dos mil
                            unidades (2.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si su ejemplar es retirado de cualquiera de las dos
                            (2) carreras designadas como «doble selección», el
                            dinero de la apuesta será reembolsado, y no habrá
                            pagos de consolación.
                          </li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            Galgódromo de origen, no habrá premio de
                            consolación, solo se pagará el límite de pago a los
                            boletos virtuales con la combinación acertada en
                            nuestro Sitio Web de apuestas, siendo igualmente
                            aplicable el pago máximo.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), el
                              dinero de la apuesta será reembolsado.</u
                            >
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Trifecta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Trifecta si selecciona
                      a los ejemplares ganadores del primer, segundo y tercer
                      lugar en el orden exacto de llegada, en las carreras
                      designadas con apuesta de Trifecta.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es mil unidades (1.000 U.) por
                            cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de tres mil
                            unidades (3.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si en el evento su ejemplar es retirado, el dinero
                            de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el Galgódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de los tres
                            (3) lugares, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los Galgódromo
                            de origen, no siendo aplicable el pago de este
                            premio en nuestro sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            Galgódromo de origen, no habrá premio de
                            consolación, solo se pagará el límite de pago a los
                            boletos virtuales con la combinación acertada en
                            nuestro Sitio Web de apuestas, siendo igualmente
                            aplicable el pago máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En una carrera con cinco (5) o menos ejemplares, no
                            habrá apuesta de TRIFECTA, y se reembolsará el
                            dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Triple Selección (Pick 3)</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de triple selección si
                      selecciona al ejemplar ganador del primer lugar en tres
                      carreras consecutivas designadas con apuesta de triple
                      selección.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es mil unidades (1.000 U.) por
                            cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de tres mil
                            unidades (3.000U.), sin importar las veces apostadas
                            en el boleto.
                          </li>
                          <li>No habrá reembolsos por los retirados.</li>
                          <li>
                            Si su ejemplar es retirado del evento, éste será
                            sustituido por el primer ejemplar favorito al cierre
                            de la carrera; y en caso, de que el ejemplar
                            favorito resulte ganador, se pagará por el favorito
                            ganador y por cada ejemplar retirado del evento.
                          </li>
                          <li>
                            En caso de que una o más carreras sean canceladas,
                            el dinero de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en Galgódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de las tres
                            (3) carreras, por no haber combinación ganadora,
                            esto equivale a premio de consolación para los
                            Galgódromo de origen, no siendo aplicable el pago de
                            este premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            Galgódromo de origen, no habrá premio de
                            consolación, solo se pagará el límite de pago a los
                            boletos virtuales con la combinación acertada en
                            nuestro Sitio Web de apuestas, siendo igualmente
                            aplicable el pago máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>No hay acumulados.</li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), y el
                              Galgódromo de origen pagare con todos los
                              ejemplares participantes, nuestro Sitio Web de
                              apuestas aplicará:</u
                            >
                            <ol>
                              <li>
                                Los resultados de pago del hipódromo de origen;
                              </li>
                              <li>
                                Solo pagará los tickets con la combinación
                                acertada;
                              </li>
                              <li>
                                Pagará los ejemplares adicionales que hayan sido
                                seleccionados por el apostador en la carrera
                                donde hubo cambio de pista.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Superfecta</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Superfecta si
                      selecciona a los ejemplares ganadores del primer, segundo,
                      tercero y cuarto lugar, en el orden exacto de llegada, en
                      carreras designadas con apuesta de Superfecta.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es de mil quinientas unidades
                            (1.500 U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de cinco mil
                            unidades (5.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>
                            Si en el evento su ejemplar es retirado, el dinero
                            de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el Galgódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de los cuatro
                            (4) lugares, por no haber combinación ganadora, esto
                            equivale a premio de consolación para los Galgódromo
                            de origen, no siendo aplicable el pago de este
                            premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            Galgódromo de origen, no habrá premio de
                            consolación, solo se pagará el límite de pago a los
                            boletos virtuales con la combinación acertada en
                            nuestro Sitio Web de apuestas, siendo igualmente
                            aplicable el pago máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>
                            En una carrera con seis (6) o menos ejemplares, no
                            habrá apuesta de SUPERFECTA, y se reembolsará el
                            dinero.
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                  <tr style="background: #dbf4ff;">
                    <td class="text-center">
                      <b>Pick 4</b>
                    </td>
                    <td>
                      El apostador cobrará la apuesta de Pick 4 si selecciona al
                      ejemplar ganador del primer lugar en cuatro carreras
                      consecutivas designadas con apuesta de pick 4.
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>
                        <ul>
                          <li>
                            El límite de pago es mil quinientas unidades (1.500
                            U.) por cada unidad apostada.
                          </li>
                          <li>
                            El pago máximo por boleto virtual es de cinco mil
                            unidades (5.000 U.), sin importar las veces
                            apostadas en el boleto.
                          </li>
                          <li>No habrá reembolsos por los retirados.</li>
                          <li>
                            Si su ejemplar es retirado del evento, éste será
                            sustituido por el primer ejemplar favorito del
                            Galgódromo de origen al cierre de la carrera; y en
                            caso, de que el ejemplar favorito resulte ganador,
                            se pagará por el favorito ganador y por cada
                            ejemplar retirado del evento.
                          </li>
                          <li>
                            En caso de que una o más carreras sean canceladas,
                            el dinero de la apuesta será reembolsado.
                          </li>
                          <li>
                            Si en el Galgódromo de origen paga con todos los
                            ejemplares participantes en cualquiera de las cuatro
                            (4) carreras, por no haber combinación ganadora,
                            esto equivale a premio de consolación para los
                            Galgódromo de origen, no siendo aplicable el pago de
                            este premio en nuestro Sitio Web de apuestas.
                          </li>
                          <li>No hay pagos de consolación.</li>
                          <li>
                            En caso de que no haya boleto ganador en el
                            Galgódromo de origen, no habrá premio de
                            consolación, solo se pagará el límite de pago a los
                            boletos con la combinación acertada en los
                            establecimientos afiliados a nuestro Sitio Web de
                            apuestas, siendo igualmente aplicable el pago
                            máximo.
                          </li>
                          <li>
                            Entries y fields (llaves) corren bajo un solo
                            interés.
                          </li>
                          <li>No hay acumulados.</li>
                          <li>
                            <u
                              >Si en el evento se presentare situaciones o
                              circunstancias no previstas en estas reglas, caso
                              fortuito o de fuerza mayor (ejemplo: problemas de
                              energía eléctrica, cambio de pista, etc.), y el
                              Galgódromo de origen pagare con todos los
                              ejemplares participantes, nuestro Sitio Web de
                              apuestas aplicará:</u
                            >
                            <ol>
                              <li>
                                Los resultados de pago del hipódromo de origen;
                              </li>
                              <li>
                                Solo pagará los tickets con la combinación
                                acertada;
                              </li>
                              <li>
                                Pagará los ejemplares adicionales que hayan sido
                                seleccionados por el apostador en la carrera
                                donde hubo cambio de pista.
                              </li>
                            </ol>
                          </li>
                        </ul>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <b>POLÍTICAS GENERALES DE TODOS LOS JUEGOS HÍPICOS</b>
      <br />
      <br />
      <table class="tabl">
        <thead>
          <tr>
            <th class="text-center" colspan="2">GLOSARIO</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Entry</b>
            </td>
            <td>
              Dos o más caballos propiedad del mismo establo o entrenados por el
              mismo entrenador se denominan como entry y se unen como un solo
              interés de apuesta, una apuesta a un caballo unido es una apuesta
              a todos los caballos con quién esté unido.
            </td>
          </tr>
          <tr>
            <td>
              <b>Field</b>
            </td>
            <td>
              Un caballo agrupado con otro(s) bajo un mismo interés donde el
              número de caballos que arrancan excede el número de intereses de
              apuesta que el tablero del hipódromo de origen pueda manejar, una
              apuesta a un caballo del field es una apuesta a todos los caballos
              del field común.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table class="tabl">
        <tbody>
          <tr>
            <td>
              <b>Apuestas Futuras</b>
            </td>
            <td>
              Consisten en ciertos eventos como el Derby de Kentucky, Copa de
              Criadores, Triple Corona, etc.
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <b>
                <ul>
                  <li>
                    Estas apuestas son consideradas como apuesta derecha, y
                    pagan de acuerdo al monto con el que se realizó la apuesta.
                  </li>
                  <li>
                    En caso de que un ejemplar sea retirado, no habrá
                    devoluciones.
                  </li>
                  <li>
                    Entries y fields (llaves) corren bajo un solo interés.
                  </li>
                </ul>
              </b>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table class="tabl">
        <tbody>
          <tr>
            <td>
              <b>
                Eventos Especiales
              </b>
            </td>
            <td>
              Esta es una apuesta a ciertos eventos designados de relevancia
              internacional y/o Nacional.
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <b>
                <ul>
                  <li>
                    Estas apuestas son consideradas Apuestas Derechas y
                    aplicarán las reglas y límites de apuesta indicados en la
                    Hoja de Apuestas correspondiente a cada evento específico.
                  </li>
                </ul>
              </b>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table class="tabl">
        <tbody>
          <tr>
            <td>
              <b>Apuesta</b>
            </td>
            <td>
              Es cada jugada que el apostador realiza, la cual se evidencia en
              el módulo correspondiente a Jugadas realizadas en el perfil del
              usuario.
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table class="tabl">
        <tbody>
          <tr>
            <td>
              <b>Primer Ejemplar Favorito</b>
            </td>
            <td>Es el ejemplar más apostado al cierre de la carrera.</td>
          </tr>
          <tr>
            <td colspan="2">
              <b>
                <ul>
                  <li>
                    Es el ejemplar con mayor cantidad de apuestas recibidas en
                    el hipódromo de origen al cierre de la carrera.
                  </li>
                  <li>
                    Se considerará como el favorito al cierre de la carrera,
                    para efecto de ejemplares retirados donde la apuesta
                    condicione al favorito para el pago de la misma.
                  </li>
                </ul>
              </b>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table class="tabl">
        <thead>
          <tr>
            <th colspan="2" class="text-center">
              POLÍTICA PARA LA TOMA DE APUESTAS EN CARRERAS
            </th>
          </tr>
          <tr>
            <th class="text-center">HIPÓDROMO CLASE</th>
            <th>POLÍTICA DE TOMA DE APUESTAS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center">
              <h1>A</h1>
            </td>
            <td>
              <ul>
                <li>
                  Ganador / Place / Show
                  <ul>
                    <li>
                      El límite de apuesta es de 3.000 unidades a Ganador, Place
                      y Show por competidor.
                    </li>
                    <li>
                      Por cada apuesta a un competidor se expide un solo boleto
                      virtual.
                    </li>
                    <li>
                      Estos límites aplican para uno o más competidores por cada
                      carrera.
                    </li>
                  </ul>
                </li>
                <li>
                  Exóticas
                  <ul>
                    <li>
                      Apuestas menores a 100 unidades se hacen en un solo boleto
                      virtual.
                    </li>
                    <li>
                      Por cada apuesta a un competidor se expide un solo boleto
                      virtual.
                    </li>
                    <li>
                      Lo anterior se aplica por cada tipo de apuesta exótica
                      (Quiniela, Exacta, Trifecta, Superfecta, Doble Selección,
                      Triple Selección, Parlay, etc.)
                    </li>
                    <li>
                      Estos límites aplican por cada tipo de apuesta exótica con
                      combinación de competidores distintos por cada carrera.
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <h1>B</h1>
            </td>
            <td>
              <ul>
                <li>
                  Ganador / Place / Show
                  <ul>
                    <li>
                      El límite de apuesta derecha es de 500 unidades a Ganador,
                      Place y Show por competidor.
                    </li>
                    <li>
                      Por cada apuesta a un competidor se expide un solo boleto
                      virtual.
                    </li>
                    <li>
                      Estos límites aplican para uno o más competidores por cada
                      carrera.
                    </li>
                  </ul>
                </li>
                <li>
                  Exóticas
                  <ul>
                    <li>
                      Apuestas menores a 100 unidades se hacen en un solo boleto
                      virtual.
                    </li>
                    <li>
                      Por cada apuesta a un competidor se expide un solo boleto
                      virtual.
                    </li>
                    <li>
                      Lo anterior se aplica por cada tipo de apuesta exótica
                      (Quiniela, Exacta, Trifecta, Superfecta, Doble Selección,
                      Triple Selección, Parlay, etc.)
                    </li>
                    <li>
                      Estos límites aplican por cada tipo de apuesta exótica con
                      combinación de competidores distintos por cada carrera.
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <h1>C</h1>
            </td>
            <td>
              <ul>
                <li>
                  Ganador / Place / Show
                  <ul>
                    <li>
                      El límite de apuesta derecha es de 200 unidades a Ganador,
                      Place y Show por competidor.
                    </li>
                    <li>
                      Por cada apuesta a un competidor se expide un solo boleto
                      virtual.
                    </li>
                    <li>
                      Estos límites aplican para uno o más competidores por cada
                      carrera.
                    </li>
                  </ul>
                </li>
                <li>
                  Exóticas
                  <ul>
                    <li>El límite de apuestas exóticas es de 100 unidades.</li>
                    <li>
                      Por cada apuesta a un competidor se expide un solo boleto
                      virtual.
                    </li>
                    <li>
                      Lo anterior se aplica por cada tipo de apuesta exótica
                      (Quiniela, Exacta, Trifecta, Superfecta, Doble Selección,
                      Triple Selección, Parlay, etc.)
                    </li>
                    <li>
                      Estos límites aplican por cada tipo de apuesta exótica con
                      combinación de competidores distintos por cada carrera.
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <h1>D</h1>
            </td>
            <td>
              <ul>
                <li>
                  Ganador / Place / Show
                  <ul>
                    <li>
                      El límite de apuesta derecha es de 100 unidades a Ganador,
                      Place y Show por competidor.
                    </li>
                    <li>
                      Por cada apuesta a un competidor se expide un solo boleto
                      virtual.
                    </li>
                    <li>
                      Estos límites aplican para uno o más competidores por cada
                      carrera.
                    </li>
                  </ul>
                </li>
                <li>
                  Exóticas
                  <ul>
                    <li>El límite de apuestas exóticas es de 50 unidades.</li>
                    <li>
                      Por cada apuesta a un competidor se expide un solo boleto
                      virtual.
                    </li>
                    <li>
                      Lo anterior se aplica por cada tipo de apuesta exótica
                      (Quiniela, Exacta, Trifecta, Superfecta, Doble Selección,
                      Triple Selección, Parlay, etc.)
                    </li>
                    <li>
                      Estos límites aplican por cada tipo de apuesta exótica con
                      combinación de competidores distintos por cada carrera.
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table class="tabl">
        <thead>
          <tr>
            <th class="text-center" colspan="4">
              CUADRO RESUMEN DE LA TOMA DE APUESTAS
            </th>
          </tr>
          <tr>
            <th class="text-center" colspan="4">
              CLASES DE HIPÓDROMOS Y GALGODROMOS
            </th>
          </tr>
          <tr>
            <th class="text-center">Clase “A”</th>
            <th class="text-center">Clase “B”</th>
            <th class="text-center">Clase “C”</th>
            <th class="text-center">Clase “D”</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Und 3.000 GANADOR
              <br />Und 3.000 PLACE <br />Und 3.000 SHOW
            </td>
            <td>
              Und 500 GANADOR
              <br />Und 500 PLACE <br />Und 500 SHOW
            </td>
            <td>
              Und 200 GANADOR
              <br />Und 200 PLACE <br />Und 200 SHOW
            </td>
            <td>
              Und 100 GANADOR
              <br />Und 100 PLACE <br />Und 100 SHOW
            </td>
          </tr>
          <tr>
            <th colspan="4" class="text-center">NOTA</th>
          </tr>
          <tr>
            <td colspan="4">
              <ul>
                <li>Und abreviación de unidades de apuestas.</li>
                <li>
                  Número de boletos virtuales igual a Nº de apuestas, es decir,
                  un boleto virtual equivale a una apuesta.
                </li>
                <li>
                  Cuándo los clientes soliciten apuestas por encima de los
                  límites máximos establecidos por la empresa, consultar la
                  política para autorización a clientes especiales.
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table class="tabl">
        <thead>
          <tr>
            <th class="text-center" colspan="4">
              CUADRO RESUMEN DE LA TOMA DE APUESTAS GALGODROMOS
            </th>
          </tr>
          <tr>
            <th class="text-center" colspan="4">
              TODOS LOS GALGODROMOS SE CONSIDERAN CLASE “D”
            </th>
          </tr>
          <tr>
            <th class="text-center"></th>
            <th class="text-left">Derecha</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>APUESTA MAXIMA</td>
            <td>
              Und 100 GANADOR
              <br />Und 100 PLACE <br />Und 100 SHOW
            </td>
          </tr>
          <tr>
            <th colspan="4" class="text-center">
              LAS APUESTAS DE GALGODROMOS ESTAN LIMITADOS
            </th>
          </tr>
          <tr>
            <td colspan="4">
              <ul>
                <li>
                  EL PAGO SE HARÁ DE LA SIGUIENTE FORMA:
                  <ul>
                    <li>GANADOR: 15 unidades por cada unidad apostada</li>
                    <li>PLACE: 8 unidades por cada unidad apostada</li>
                    <li>SHOW: 4 unidades por cada unidad apostada</li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <table class="tabd">
        <div class="mt-3 rounded white pa-3 text-justify">
          <p><strong>REGLAS DE DEPORTES</strong></p>
          <p>
            La siguiente tabla le indica cuando un juego se considera oficial
            para efectos de apuestas:
          </p>
          <table border="1" CELLSPACING="0">
            <tbody>
              <tr>
                <td width="311">
                  <p>Pro Football</p>
                </td>
                <td width="311">
                  <p>55 minutos de juego</p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>College Football</p>
                </td>
                <td width="311">
                  <p>55 minutos de juego</p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Baloncesto</p>
                </td>
                <td width="311">
                  <p>
                    Todas las competencias/ligas excepto la NBA deben jugar como
                    minimo 35 minutos para tener acci&oacute;n, al menos que se
                    especifique otra cosa.
                  </p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Pro Basketball</p>
                </td>
                <td width="311">
                  <p>43 minutos de juego</p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>College Basketball</p>
                </td>
                <td width="311">
                  <p>35 minutos de juego</p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Hockey</p>
                </td>
                <td width="311">
                  <p>55 minutos de juego</p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Baseball Sides</p>
                </td>
                <td width="311">
                  <p>
                    5 entradas (4 1/2 si el equipo de casa est&aacute; ganando)
                  </p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Baseball Totals</p>
                </td>
                <td width="311">
                  <p>
                    9 entradas (8 1/2 si el equipo de casa est&aacute; ganando)
                  </p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Linea de la carrera</p>
                </td>
                <td width="311">
                  <p>
                    9 entradas (8 1/2 si el equipo de casa est&aacute; ganando)
                  </p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Tennis</p>
                </td>
                <td width="311">
                  <p>Bola servida</p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Soccer</p>
                </td>
                <td width="311">
                  <p>
                    Si un partido es suspendido antes de completarse el tiempo
                    del juego, y no se completa el mismo d&iacute;a, las
                    apuestas sobre el resultado del partido se
                    considerar&aacute;n nulas y todas las apuestas seran
                    devueltas. Incluye Goal Lines, Money lines and Totals.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <strong><strong>Eventos Deportivos</strong></strong>
          </p>
          <ul>
            <li>
              Todos los eventos deportivos deben jugarse en la fecha y el lugar
              programado, a menos que se especifique lo contrario. Excepciones:
              carreras de autos, golf, tenis, MMA, UFC y boxeo.
            </li>
            <li>
              Las carreras de autos, el golf, el tenis, las artes marciales
              mixtas, la UFC y el boxeo no est&aacute;n sujetos a esta regla.
              Las apuestas seguir&aacute;n siendo v&aacute;lidas sujetas a que
              el organismo rector de cada deporte cancele el evento.
            </li>
            <li>
              Si alg&uacute;n evento es pospuesto y/o reprogramado, el evento
              constituir&aacute; autom&aacute;ticamente &laquo;sin
              acci&oacute;n&raquo;.
            </li>
            <li>
              No reconocemos juegos suspendidos, protestas o decisiones anuladas
              con multas de apuestas.
            </li>
            <li>
              Las reglas de Las Vegas se aplican a cualquier apuesta no cubierta
              por las reglas y aplican aqu&iacute;.
            </li>
            <li>
              Para los eventos de ciclismo, los ciclistas pueden recibir el
              mismo tiempo, pero se les otorgar&aacute; un orden de llegada que
              se decidir&aacute; por foto de finalizaci&oacute;n. Los resultados
              se tomar&aacute;n desde la posici&oacute;n final oficial en el
              sitio web principal del evento.
            </li>
            <li>
              Para todos los accesorios de jugadores o equipos, utilizaremos el
              sitio web oficial del evento deportivo para declarar al ganador.
              Ejemplos: WWW.NFL.COM, WWW.NBA.COM, etc. Para eventos que no
              tienen un sitio web asociado, utilizaremos WWW.ESPN.COM para los
              resultados.
            </li>
          </ul>
          <br />
          <p>
            <strong><strong>Para todos los eventos deportivos:</strong></strong>
          </p>
          <p>
            Nos reservamos el derecho de cancelar cualquier apuesta aceptada
            cuando la l&iacute;nea obviamente se public&oacute; incorrectamente.
          </p>
          <p>
            Las apuestas tambi&eacute;n se cancelar&aacute;n cuando haya pruebas
            de trampa, intento de pirater&iacute;a o enga&ntilde;o con
            conocimiento de causa.
          </p>
          <p>
            Tambi&eacute;n nos reservamos el derecho de cancelar las apuestas
            realizadas despu&eacute;s de que un juego sea final o se pueda
            deducir un resultado del puntaje.
          </p>
          <p>
            Los enfrentamientos que se publican incorrectamente se
            cancelar&aacute;n autom&aacute;ticamente. Estos incluyen
            participantes y emparejamientos incorrectos. introducido
            incorrectamente en el sistema.
          </p>
          <p>
            <strong><strong>FOOTBALL</strong></strong>
          </p>
          <p>
            <strong><strong>NFL</strong></strong>
          </p>
          <ul>
            <li>
              Los totales individuales de cada equipo para el segundo tiempo
              incluyen tiempos extra.
            </li>
            <li>Las l&iacute;neas del 4to Cuarto no incluyen tiempos extra.</li>
            <li>
              Las Quinielas (Pools) con doble resultado consisten en el ganador
              del Primer Tiempo y el Ganador del Juego.
            </li>
            <li>
              Las Quinielas de Primer Jugada Anotadora y Margen de Victoria
              incluyen tiempos extra. Las Quinielas de Doble Resultado solamente
              incluyen tiempos extra en TEMPORADA REGULAR.
            </li>
            <li>
              En caso de que la fecha y/o el lugar del Super Taz&oacute;n se
              cambien debido a condiciones clim&aacute;ticas severas, todas las
              apuestas abiertas existentes en el juego y las apuestas
              relacionadas con el juego (props) se considerar&aacute;n de
              acci&oacute;n y siguen en pie hasta que el partido se juegue.
            </li>
          </ul>
          <p>
            * Debido a Covid-19 para la temporada 2020-21, todos los juegos
            programados antes del inicio de la temporada tendr&aacute;n
            acci&oacute;n independientemente de si hay un cambio de fechas,
            siempre y cuando se jueguen en la ubicaci&oacute;n especificada
            (ESTA REGLA APLICA PARA JUEGOS QUE FUERON OFRECIDOS ANTES EL INICIO
            DE LA TEMPORADA REGULAR).
          </p>
          <p>
            * 2ndo Tiempo y cada Cuarto deben de completarse en su totalidad
            para que la apuesta sea v&aacute;lida.
          </p>
          <p>
            * Las apuestas del Segundo Tiempo siempre incluyen tiempos extra.
          </p>
          <p>
            Los equipos deben jugar los 17 juegos de la temporada regular para
            tener acci&oacute;n, si un juego empata, se otorgar&aacute; medio
            punto para cada equipo.
          </p>
          <p>
            <strong><strong>F&uacute;tbol Universitario (CFB)</strong></strong>
          </p>
          <ul>
            <li>
              Los Totales por Equipo para el Segunto Tiempo incluyen tiempos
              extra.
            </li>
            <li>
              Las l&iacute;neas para el 4o Cuarto NO incluyen tiempos extra.
            </li>
            <li>
              Resultado en pools dobles, consisten en el ganador del 1er tiempo
              y el ganador del partido.
            </li>
            <li>
              Los grupos de 1ra jugada de anotaci&oacute;n y margen de victoria
              incluyen tiempo extra. Los grupos de resultados dobles NO incluye
              el tiempo extra
            </li>
            <li>
              2ndo Tiempo y cada Cuarto deben de completarse en su totalidad
              para que la apuesta sea v&aacute;lida.
            </li>
            <li>
              Las apuestas del Segundo Tiempo siempre incluyen tiempos extra
            </li>
          </ul>
          <br />
          <p>
            <strong><strong>BALONCESTO</strong></strong>
          </p>
          <p>
            <strong><strong>NBA</strong></strong>
          </p>
          <ul>
            <li>
              Los Totales por Equipo para el Segundo Tiempo incluyen tiempos
              extra.
            </li>
            <li>
              Las l&iacute;neas para el 4o Cuarto NO incluyen tiempos extra.
            </li>
            <li>
              Las Quinielas (Pools) de Doble Resultado consisten en el ganador
              del 1er tiempo y el ganador del partido.
            </li>
            <li>
              Las Quinielas (Pools) para ambos Doble Resultado y Margen de
              Victoria incluyen tiempos extra.
            </li>
          </ul>
          <p>
            * * Las apuestas del Segundo Tiempo siempre incluyen tiempos extra.
          </p>
          <p>
            <strong><strong>BEISBOL</strong></strong>
          </p>
          <ul>
            <li>
              Los juegos son oficiales despu&eacute;s de 5 entradas de juego a
              menos que el equipo local est&eacute; ganando despu&eacute;s de 4
              entradas y media.
            </li>
            <li>
              Despu&eacute;s de 5 entradas de juego, si el juego es llamado o
              suspendido, la puntuaci&oacute;n final (para prop&oacute;sitos de
              apuesta) se determina por la &uacute;ltima entrada completa de
              juego a menos que el equipo local anota para empatar o tome la
              delantera en la mitad inferior de la entrada. En cuyo caso, el
              ganador se determina por la puntuaci&oacute;n cuando se llama al
              juego
            </li>
            <li>
              Los Juegos de Temporada Regular que se suspenden o posponen no se
              transfieren al d&iacute;a siguiente. Sin embargo, para la
              postemporada de la MLB, la final del Cl&aacute;sico Mundial de
              B&eacute;isbol (WBC) y los juegos de Semifinales, Post temporada
              de baseball internacional y finales World series de
              peque&ntilde;as ligas que se suspenden o posponen, las apuestas se
              transferir&aacute;n hasta que el juego se pronuncie como
              definitivo.
            </li>
            <li>
              Los juegos de la Serie Mundial Universitaria que est&aacute;n
              suspendidos se consideran en un largo retraso de lluvia y
              ser&aacute;n calificados cuando el juego llegue a su final
              oficial.
            </li>
            <li>
              Partidos MLB Programados a 7 Innings : Para que las apuestas en la
              L&iacute;nea de Carreras y en el Total sean v&aacute;lidas se
              deben de completar los 7 innings o 6.5 si el equipo casa va
              ganado.
            </li>
            <li>
              Cualquier partido de baseball Mexicano debe ser completado en al
              menos en 8.5 entradas si el equipo casa est&aacute; ganando o 9
              entradas si no es as&iacute; para que la l&iacute;nea de la
              carrera y el total tengan acci&oacute;n.
            </li>
            <li>
              Los juegos de exhibici&oacute;n ser&aacute;n juegos de 9 entradas
              o pueden acortarse a juegos de 7 entradas o juegos de 5 entradas
              por mutuo acuerdo de ambos directores.
            </li>
            <li>
              Los juegos acortados ser&aacute;n tratados como juegos acortados
              por lluvia.
            </li>
          </ul>
          <br />
          <p>
            <strong><strong>L&iacute;neas de B&eacute;isbol</strong></strong>
          </p>
          <p>
            Los juegos de B&eacute;isbol se pueden apostar de tres maneras
            diferentes: L&iacute;nea de dinero, L&iacute;nea de carrera y
            Totales. Para las l&iacute;neas de carreras y Totales utilizamos una
            l&iacute;nea de 20 centavos. El favorito tiene el n&uacute;mero
            negativo al lado que muestra el precio que tiene que poner para
            jugar ese lado y el desfavorable casi siempre tiene un n&uacute;mero
            positivo al lado que muestra el precio que ganar&iacute;a jugando. A
            continuaci&oacute;n se muestra un ejemplo de un juego de
            b&eacute;isbol t&iacute;pico.
          </p>
          <table>
            <tbody>
              <tr>
                <td width="207">
                  <p>EQUIPOS</p>
                </td>
                <td width="207">
                  <p>LINEA DEL DINERO</p>
                </td>
                <td width="207">
                  <p>TOTAL</p>
                </td>
              </tr>
              <tr>
                <td width="207">
                  <p>Mets &ndash; Hampton</p>
                </td>
                <td width="207">
                  <p>-134</p>
                </td>
                <td width="207">
                  <p>9ov -105</p>
                </td>
              </tr>
              <tr>
                <td width="207">
                  <p>Marlins &ndash; Dempster</p>
                </td>
                <td width="207">
                  <p>+124</p>
                </td>
                <td width="207">
                  <p>9ov -105</p>
                </td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              Si quiere hacer una apuesta de $100 en los Mets, estar&iacute;a
              arriesgando $134 para ganar $100.
            </li>
            <li>
              Si quiere hacer una apuesta de $100 en los Marlins, estar&iacute;a
              arriesgando $100 para ganar $124.
            </li>
            <li>
              Si quiere hacer una apuesta de $100 en las altas de 9 carreras,
              estar&iacute;a arriesgando $105 para ganar $100
            </li>
            <li>
              Si quiere hacer una apuesta de $100 en las bajas de 9 carreras,
              estar&iacute;a arriesgando $115 para ganar $100.
            </li>
          </ul>
          <p>
            Al apostar L&iacute;neas de Dinero de B&eacute;isbol, puede elegir
            las siguientes opciones para sus apuestas: ACCI&Oacute;N, LISTADOS,
            o puede escoger solamente el lanzador de SU EQUIPO o el EQUIPO
            OPUESTO.
          </p>
          <p>
            En MLB cuando un equipo tenga ACTION como su lanzador, entonces sin
            importar el lanzador que inicie la jugada tendr&aacute;
            acci&oacute;n.
          </p>
          <p>
            NOTA: Los lanzadores iniciales se determinan despu&eacute;s del
            primer paso. En caso de un cambio de lanzadores antes del juego, el
            precio del dinero puede ajustarse y su apuesta puede cambiar si
            juega con acci&oacute;n.
          </p>
          <p>
            <strong><strong>Acci&oacute;n:</strong></strong>
          </p>
          <p>
            Su apuesta es equipo contra equipo, independientemente de
            qui&eacute;nes sean los lanzadores titulares. En caso de un cambio
            de lanzadores, su precio se ajustar&aacute; al n&uacute;mero de
            apertura del lanzador inicial real.
          </p>
          <p>
            <strong><strong>Lanzadores Listados:</strong></strong>
          </p>
          <p>
            Su apuesta se basa en ambos lanzadores listados lanzando al menos
            una vez al inicio del juego para que usted tenga acci&oacute;n.
            Ambos lanzadores deben comenzar para que usted tenga acci&oacute;n
            en su apuesta, de lo contrario la apuesta ser&aacute; declarada SIN
            ACCI&Oacute;N despu&eacute;s de que el juego termine.
          </p>
          <p>
            <strong><strong>Lanzador de su equipo:</strong></strong>
          </p>
          <p>
            Solo est&aacute; tomando el lanzador listado del equipo en el que
            est&aacute; apostando. El lanzador seleccionado debe iniciar el
            juego para que su apuesta cuente. Cualquier lanzador puede empezar
            para el equipo contrario. En caso de un cambio de lanzador en el
            equipo contrario, el precio seguir&aacute; siendo ajustado al precio
            de apertura de los titulares listados al principio.
          </p>
          <p>
            <strong><strong>Lanzador Opuesto:</strong></strong>
          </p>
          <p>
            Est&aacute; escogiendo &uacute;nicamente al lanzador del equipo
            contrario. Cualquier abridor puede lanzar para el equipo que
            seleccion&oacute;. El lanzador del equipo contrario tiene que
            empezar. En el caso de un cambio de lanzador en su equipo, el precio
            se ajustar&aacute; al precio de apertura de los titulares listados
            al principio.
          </p>
          <p>
            La otra forma de apostar en un partido de b&eacute;isbol es apostar
            la l&iacute;nea de carrera. Cada favorito ser&aacute; &ndash; 1 1/2
            carreras y podr&iacute;a ser m&aacute;s o menos dinero. A
            continuaci&oacute;n se muestra un ejemplo de c&oacute;mo se ve una
            l&iacute;nea de carreras:
          </p>
          <table>
            <tbody>
              <tr>
                <td width="311">
                  <p>Mets &ndash; Hampton</p>
                </td>
                <td width="311">
                  <p>+1 &frac12; +115</p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Marlins &ndash; Dempster</p>
                </td>
                <td width="311">
                  <p>-1 &frac12; -135</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Si usted apuesta $100 en los Mets, tiene a los Mets +1&frac12;
            carreras, arriesga $100 para ganar $115
          </p>
          <p>
            Al apostar en Total de Carreras (Altas/Bajas) y L&iacute;neas de
            Carrera, el juego debe hacer 9 entradas (8 1/2 si el equipo local
            est&aacute; ganando).
          </p>
          <p>
            Si el juego se suspende despu&eacute;s de 9 entradas, la
            puntuaci&oacute;n final (para prop&oacute;sitos de apuesta) se
            determina por la &uacute;ltima entrada completa de juego a menos que
            el equipo local anote para empatar o tome la delantera en la mitad
            inferior de la entrada. En cuyo caso, el ganador se determina por la
            puntuaci&oacute;n cuando se detiene el juego. Si con estas reglas el
            juego es un EMPATE, las L&iacute;neas de Carreras y Totales son
            v&aacute;lidas. Todas las puestas en Totales y L&iacute;neas de
            Carrera son SIEMPRE con los Lanzadores Listados. Si ambos lanzadores
            programados para lanzar en el momento de la apuesta no lanzan al
            menos una vez al iniciar el Partido, la apuesta ser&aacute;
            cancelada. Las apuestas en Total de Carreras y las L&iacute;neas de
            Carreras se reembolsan en caso de un cambio de lanzador cuando el
            juego sea definitivo.
          </p>
          <p>
            NO aceptamos apuestas Combinadas (Parlays), Condicionadas (If Bets)
            o Round Robins con el Total y L&iacute;nea de Carrera del mismo
            partido. Cualquier apuesta de este tipo puede ser cancelada por
            nosotros sin previo aviso.
          </p>
          <p>
            <strong><strong>Regla de LLUVIA o SOL</strong></strong
            >: Las proposiciones (props) de b&eacute;isbol, incluyendo 1er
            puntaje, S&iacute;/No Puntuaci&oacute;n en la 1a entrada, las 1eras
            5 entradas, se califican tan pronto como ocurre ese evento. Para a
            l&iacute;nea de las 1eras 5 entradas, el partido debe de llegar a la
            6ta entrada de juego para que la apuesta sea v&aacute;lida. Las
            apuestas son finales incluso si el juego se detiene por lluvia o si
            es suspendido.
          </p>
          <p>
            Para cualquier Prop, el juego debe ser jugado por completo para ser
            v&aacute;lido. Juegos q son programados para solo 7 Entradas (6.5 si
            el equipo casa est&aacute; ganando) son considerados completos.
          </p>
          <p>
            <strong><strong>L&Iacute;NEA DE 2do TIEMPO:</strong></strong
            >&nbsp;La 2a mitad de la puntuaci&oacute;n de b&eacute;isbol incluye
            desde la parte superior de la sexta entrada hasta el final del juego
            incluyendo entradas extras. El juego debe ser completado ara que la
            apuesta sea v&aacute;lida (9 entradas completas u 8.5 entradas si el
            equipo local est&aacute; ganando). Todas las apuestas en la 2a mitad
            son de acci&oacute;n independientemente del lanzador. Si los
            partidos se suspenden y se han jugado M&Aacute;S de 9 entradas, la
            puntuaci&oacute;n volver&aacute; a la &uacute;ltima entrada
            COMPLETA.
          </p>
          <p>
            <strong
              ><strong
                >Proposiciones de b&eacute;isbol, las l&iacute;neas de carreras
                alternativas y mitades</strong
              ></strong
            >, TODOS los lanzadores listados deben iniciar el juego. En la
            proposici&oacute;n de Jugador para obtener la Mayor&iacute;a de las
            Bases Totales, se utiliza el siguiente sistema de puntuaci&oacute;n:
            Singular= 1 base, Doble= 2 bases, Triple= 3 bases, HR= 4 bases.
          </p>
          <p>
            Ambos jugadores lanzadores titulares deben comenzar para que la
            apuesta sea v&aacute;lida.
          </p>
          <p>
            En los eventos de la Quiniela TODOS LOS JUGADORES SON ACCI&Oacute;N
            a menos que se specifique lo contrario.
          </p>
          <p>
            Los Props de b&eacute;isbol se pueden utilizar para Apuestas
            Directas, Combinadas (Parlays), Condicionadas y/o Round Robins
            (TEASERS NO) siempre y cuando el otro equipo en tales apuestas no
            pertenezca a la misma Familia. Cualquier apuesta dentro de la misma
            familia (O PROPS UTILIZADO EN TEASERS) ser&aacute; cancelada por
            nosotros sin previo aviso.
          </p>
          <p>
            <strong><strong>Series de B&eacute;isbol:</strong></strong
            >&nbsp;Las Series de B&eacute;isbol son partidos jugados entre dos
            equipos (Solo Temporada Regular). Ninguno de los dos equipos puede
            jugar a ning&uacute;n otro oponente entre los partidos programados
            en la serie. Las siguientes son las reglas principales utilizadas
            para calificar la Serie de B&eacute;isbol:
          </p>
          <ul>
            <li>
              Todos los juegos de una serie cuentan para apostar. En una serie
              de cuatro juegos 2-2 es un empate.
            </li>
            <li>
              Una serie se considera v&aacute;lida si se ha jugado un partido.
            </li>
            <li>
              Si se detiene un juego durante la serie, este juego se
              considerar&aacute; bueno para la serie siempre y cuando uno de los
              equipos sea declarado oficialmente ganador del juego.
            </li>
            <li>
              Todas las apuestas se basan en la ACCI&Oacute;N con respecto a los
              lanzadores en cada juego.
            </li>
            <li>
              Un juego suspendido de una serie anterior que se completa no se
              considera parte de la serie actual.
            </li>
            <li>Estas reglas pueden diferir de otros sportsbook.</li>
          </ul>
          <p>
            As&iacute; que si una serie comienza el Viernes y termina el Lunes,
            se calificar&aacute; el Lunes, con todos los juegos contando para el
            resultado.
          </p>
          <p>
            <strong><strong>Serie Mundial</strong></strong
            >&nbsp;<strong><strong>Universitaria</strong></strong
            >&nbsp;(todos los juegos de b&eacute;isbol universitarios) que
            est&aacute;n suspendidos se consideran en un largo retraso de lluvia
            y ser&aacute;n calificados cuando el juego llegue a su final
            oficial.
          </p>
          <p>
            Para el Cl&aacute;sico Mundial de B&eacute;isbol (WBC), durante las
            dos primeras rondas de juego, ambos l&iacute;neas y totales tienen
            acci&oacute;n independientemente de la Regla de Misericordia. Para
            las Semifinales y la Final del CMB, la Regla de Misericordia no
            est&aacute; en vigor, por lo tanto, los juegos seguir&aacute;n las
            reglas normales de eliminatorias. En caso de discrepancia o
            art&iacute;culos no cubiertos, se aplicar&aacute;n las reglas de
            juego de Las Vegas.
          </p>
          <p>
            <strong
              ><strong>Totales, Carreras, Golpes y Errores:</strong></strong
            >&nbsp;Un partido debe completar 8 entradas y media si el equipo
            local est&aacute; ganando, o 9 si el equipo visitante est&aacute;
            ganando para que la apuesta sea v&aacute;lida.
          </p>
          <p>
            <strong><strong>GRAN SALAMI:</strong></strong
            >&nbsp;Para las apuestas del Gran Salami, cada juego en el tablero
            tiene que ir las 9 entradas completas (81/2 si el equipo local
            est&aacute; ganando) de lo contrario ser&aacute; cancelado.
          </p>
          <p>
            Los lanzadores no importan, lo que significa que si hay un cambio de
            lanzador en cualquier juego, su apuesta seguir&aacute; siendo
            v&aacute;lida.
          </p>
          <p>
            Para Liga de Gran Salami, solo se tendr&aacute;n en cuenta los
            partidos jugados en esa liga espec&iacute;fica, por ejemplo: el Gran
            Salami de la Liga Nacional solo incluir&aacute; partidas del
            calendario de la Liga Nacional y no equipos de la Liga Nacional que
            podr&iacute;an estar jugando en partidas de Interliga.
          </p>
          <p>
            <strong
              ><strong
                >** Excepci&oacute;n: Los juegos de b&eacute;isbol de la fase
                final (playoffs) no entran en la regla de partido suspendido de
                acuerdo con las directrices de las Grandes Ligas de
                B&eacute;isbol para terminar todos los juegos. Si un partido se
                suspende debido al clima u oscuridad, todas las apuestas
                permanecer&aacute;n pendientes hasta que el juego sea declarado
                definitivo.</strong
              ></strong
            >
          </p>
          <p>
            <strong><strong>Softbol y Ligas Peque&ntilde;as</strong></strong>
          </p>
          <p>
            Softbol o juegos de ligas peque&ntilde;as que terminan temprano como
            resultado de una regla de misericordia tendr&aacute;n acci&oacute;n
            en todas las apuestas (a menos que se indique lo contrario). Si un
            partido se suspende o pospone debido al clima u oscuridad, todas las
            apuestas permanecer&aacute;n pendientes hasta que el partido
            finalice.
          </p>
          <p>
            Post temporada de la NCAA --- Los juegos que terminen antes de
            tiempo como resultado de una regla de misericordia tendr&aacute;n
            acci&oacute;n en todas las apuestas, incluidos los TOTALES (a menos
            que se indique lo contrario). Si un juego se suspende o pospone
            debido al clima o la oscuridad, todas las apuestas
            permanecer&aacute;n pendientes hasta que el juego sea definitivo.
          </p>
          <p>
            Little League Baseball --- Los juegos que finalizan antes de tiempo
            como resultado de una regla de misericordia tendr&aacute;n
            acci&oacute;n en todas las apuestas, incluidos los TOTALES (a menos
            que se indique lo contrario). Si un juego se suspende o pospone
            debido al clima o la oscuridad, todas las apuestas
            permanecer&aacute;n pendientes hasta que el juego sea definitivo.
          </p>
          <p>
            <strong><strong>HOCKEY</strong></strong>
          </p>
          <ul>
            <li>
              Los partidos son oficiales despu&eacute;s de 55 minutos de juego.
            </li>
            <li>
              Las apuestas ser&aacute;n aceptadas usando la L&iacute;nea de Gol
              o l&iacute;nea de Dinero, as&iacute; como el Total de Goles
              anotados (altas/bajas) en el juego por ambos equipos.
            </li>
            <li>
              Se ofrecer&aacute;n 2 l&iacute;neas para los juegos de Hockey:
            </li>
          </ul>
          <ol>
            <li>
              <strong><strong>RESULTADO COMPLETO DEL JUEGO</strong></strong
              >&ndash; L&Iacute;NEA DE DINERO, L&Iacute;NEA DE DISCO Y TOTAL: El
              juego debe jugarse hasta su finalizaci&oacute;n. En el caso de que
              un partido se decida en la pr&oacute;rroga o en una tanda de
              penaltis, se a&ntilde;adir&aacute; un gol a la puntuaci&oacute;n
              del equipo ganador.
            </li>
            <li>
              <strong><strong>RESULTADO DE 60 MINUTOS</strong></strong
              >: L&Iacute;NEA DE DINERO, L&Iacute;NEA DE PUCK o L&Iacute;NEA DE
              GOL M&Aacute;S TOTAL: Esta apuesta se calificar&aacute;
              despu&eacute;s del tiempo reglamentario y no incluye la
              PR&Oacute;RROGA (tiempos extra) o PENALES. Los GOLES anotados en
              la pr&oacute;rroga o en los penaltis NO cuentan para ninguna
              apuesta en el RESULTADO DE 60 MINUTOS.
            </li>
          </ol>
          <p>
            A continuaci&oacute;n se muestra un ejemplo de un juego
            t&iacute;pico de hockey:
          </p>
          <table>
            <tbody>
              <tr>
                <td width="155">
                  <p>EQUIPO</p>
                </td>
                <td width="155">
                  <p>LINEA DEL GOL</p>
                </td>
                <td width="155">
                  <p>LINEA DEL DINERO</p>
                </td>
                <td width="155">
                  <p>TOTAL</p>
                </td>
              </tr>
              <tr>
                <td width="155">
                  <p>Penguins</p>
                </td>
                <td width="155">
                  <p>-1 1/2 +130</p>
                </td>
                <td width="155">
                  <p>-145</p>
                </td>
                <td width="155">
                  <p>5.5 -150</p>
                </td>
              </tr>
              <tr>
                <td width="155">
                  <p>Sharks</p>
                </td>
                <td width="155">
                  <p>+1 1/2 -150</p>
                </td>
                <td width="155">
                  <p>+125</p>
                </td>
                <td width="155">
                  <p>5.4 +130</p>
                </td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>
              Si quiere hacer una apuesta de $100 en los Ping&uuml;inos,
              estar&iacute;a arriesgando $145 para ganar $100
            </li>
            <li>
              Si quiere hacer una apuesta de $100 en los Sharks,
              arriesgar&iacute;a $100 para ganar $125.
            </li>
            <li>
              Si quiere hacer una apuesta de $100 en las altas de 5.5 goles,
              arriesgar&iacute;a $150 para ganar $100.
            </li>
            <li>
              Si quiere hacer una apuesta de $100 en las bajas de 5.5 goles,
              arriesgar&iacute;a $100 para ganar $130.
            </li>
            <li>
              Si quiere hacer una apuesta de $100 en la l&iacute;nea de gol (LG)
              de los Ping&uuml;inos -1 1/2, arriesgar&iacute;a $100 para ganar
              $130.
            </li>
          </ul>
          <p>
            Si quiere hacer una apuesta de $100 en LG Sharks + 1 &frac12; goles,
            arriesgar&iacute;a $150 para ganar $100.
          </p>
          <p>
            Ejemplo de un juego de penaltis: Un juego entra en la
            pr&oacute;rroga (tiempos extra) con una puntuaci&oacute;n de Equipo
            A 3 y Equipo B 3 (empatado 3-3) y sin puntuaciones de equipo en el
            per&iacute;odo de pr&oacute;rroga. El ganador de los tiros de
            penaltis recibir&aacute; un gol que har&aacute; el marcador final
            Equipo A 4 y Equipo B 3. Con este resultado el total para ambos
            equipos combinados ser&aacute; de 7. Cualquier apuesta de 60 MINUTOS
            RESULTADO se decidir&aacute; con el resultado de 60 minutos de 3-3
            empate.
          </p>
          <p>
            Los Props de Hockey incluyendo 1a Puntuaci&oacute;n, 1 L&iacute;nea
            de Per&iacute;odo se califican tan pronto como ocurre ese evento.
            Las apuestas son definitivas incluso si el juego se suspende.
          </p>
          <p>
            En las Proposiciones de Jugadores, ambos jugadores deben jugar en el
            partido para que la apuesta sea v&aacute;lida. En los eventos de la
            quiniela (pool) TODOS LOS JUGADORES SON V&Aacute;LIDOS a menos que
            se especifique lo contrario. Las proposiciones de hockey se pueden
            utilizar para Apuestas Directas, Combinadas (Parlays) y/o Round
            Robins (TEASERS NO) siempre y cuando el otro equipo en dichas
            apuestas no pertenezca a la misma familia. Cualquier apuesta dentro
            de la misma familia (O PROPS UTILIZADO EN TEASERS) ser&aacute;
            cancelada por nosotros sin previo aviso.
          </p>
          <p>
            <strong><strong>FUTBOL</strong></strong>
          </p>
          <p>
            Para los juegos de soccer puede haber 4 diferentes tipos de
            l&iacute;neas:
          </p>
          <ol>
            <li>
              <strong><strong>L&iacute;nea de Gol:</strong></strong
              >(90 minutos: 1er tiempo + 2do tiempo + tiempo de
              reposici&oacute;n)
            </li>
          </ol>
          <p>
            Incluye 1er tiempo + 2do tiempo + tiempo de reposici&oacute;n. No
            incluye tiempo extra ni penales despu&eacute;s los 90-minutos
            oficiales. Usted puede apostar en el equipo A, equipo B o en un
            empate.
          </p>
          <ol start="2">
            <li>
              <strong><strong>Para levantar la copa: </strong></strong>Incluye
              los 90 minutos del tiempo reglamentario m&aacute;s cualquier
              lesi&oacute;n o pr&oacute;rroga jugada. Usted apuesta por el
              equipo que avanzar&aacute; o ser&aacute; declarado ganador del
              partido, por lo tanto, cualquier tiroteo ayuda a determinar el
              ganador. Un equipo debe ganar el partido.
            </li>
          </ol>
          <ol start="3">
            <li>
              <strong><strong>Three way result:</strong></strong
              >(90-minutos: 1er tiempo + 2do tiempo + tiempo de
              reposici&oacute;n):
            </li>
          </ol>
          <p>
            Incluye 1er tiempo + 2do tiempo + tiempo de reposici&oacute;n. No
            incluye tiempo extra ni penales despu&eacute;s los 90-minutos
            oficiales. Usted puede apostar en el equipo A, equipo B o en un
            empate.
          </p>
          <ol start="4">
            <li>
              <strong><strong>Total:</strong></strong
              >(90-minutos: 1er tiempo + 2do tiempo + tiempo de
              reposici&oacute;n):
            </li>
          </ol>
          <p>
            Incluye 1er y 2do tiempo m&aacute;s tiempo de reposici&oacute;n. No
            incluye penales despu&eacute;s de los 90 minutos ni tiempo extra a
            menos que se especifique lo contrario.
          </p>
          <ol start="5">
            <li>
              <strong><strong>Marcador correcto: </strong></strong>(90-minutos:
              1er tiempo + 2do tiempo + tiempo de reposici&oacute;n): Incluye
              1er y 2do tiempo m&aacute;s tiempo de reposici&oacute;n. No
              incluye penales despu&eacute;s de los 90 minutos ni tiempo extra a
              menos que se especifique lo contrario.
            </li>
            <li>
              <strong><strong>Resultado doble:</strong></strong
              >(1era mitad + resultado del juego): incluye al 1ra y la 2da mitad
              + tiempo de reposici&oacute;n. No incluye penales despu&eacute;s
              de los 90 minutos ni tiempo extra a menos que se especifique lo
              contrario.
            </li>
            <li>
              <strong><strong>Margen de Victoria:</strong></strong
              >(90-minutos: 1er tiempo + 2do tiempo + tiempo de
              reposici&oacute;n):
            </li>
          </ol>
          <p>
            Incluye 1er y 2do tiempo y tiempo de reposici&oacute;n. No incluye
            penales despu&eacute;s de los 90 minutos ni tiempo extra a menos que
            se especifique lo contrario.
          </p>
          <ol start="8">
            <li>Props en futbol:</li>
          </ol>
          <ul>
            <li>El jugador tiene que jugar para tener acci&oacute;n.</li>
            <li>
              Todos los PROPS son para el tiempo de regulaci&oacute;n de 90
              minutos m&aacute;s el tiempo de reposici&oacute;n, NO incluyen
              tiempos extra o tandas de penales, a menos que se especifique lo
              contrario.
            </li>
            <li>
              Para el prop &laquo;equipo que anota de ultimo&raquo; un segundo
              gol tiene que constarse para tener acci&oacute;n.
            </li>
          </ul>
          <ol start="9">
            <li>
              <strong><strong>Gol Tempranero/Tard&iacute;o</strong></strong>
            </li>
          </ol>
          <p>
            La calificaci&oacute;n de estos props se determinar&aacute; con el
            tiempo proporcionado por el sitio web oficial, no por la hora real
            en que se produjo el gol. En f&uacute;tbol, si se marca un gol a las
            22:35, la hora oficial para ese gol ser&aacute; las 23:00.
          </p>
          <p>
            Por ejemplo, si el tiempo puesto del Gol Tempranero era 26:00 y el
            gol fue hecho a la 25:20, haciendo que la hora oficial de ese gol
            fuera a las 26:00, entonces el NO GOL ANTES DE 26:00 ser&iacute;a el
            ganador.
          </p>
          <p>
            <strong><strong>L&iacute;neas Asian Handicap</strong></strong>
          </p>
          <p>
            <strong><strong>Linea de Gol &frac14; (0.25).</strong></strong>
          </p>
          <p>
            <strong><strong>Equipo -&frac14; (-0.25) goles:</strong></strong>
          </p>
          <p>
            Gane por cualquier resultado. Todas las apuestas son ganadoras.
            Empate: Mitad del dinero en riesgo se reintegra. La otra mitad se
            declara como perdedora. Perdida por cualquier resultado: Todas las
            apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Equipo +&frac14;</strong></strong
            >&nbsp;(+0.25) goles:
          </p>
          <p>
            Gane por cualquier resultado. Todas las apuestas son ganadoras.
            Empate : La mitad de la apuesta gana al precio fijado. La otra mitad
            se reembolsa. Perdida por cualquier resultado. Todas las apuestas
            son perdedoras.
          </p>
          <p>
            <strong
              ><strong>L&iacute;nea de Gol &frac12; (0.5).</strong></strong
            >
          </p>
          <p>
            <strong><strong>Equipo -&frac12; </strong></strong>(-0.5) goles:
          </p>
          <p>
            Gane por cualquier resultado: Todas las apuestas son ganadoras.
            Empate: Todas las apuestas son perdedoras. Perdida por cualquier
            resultado: Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Equipo +&frac12;</strong></strong
            >&nbsp;(+0.5) goles:
          </p>
          <p>
            Gane por cualquier resultado: Todas las apuestas son ganadoras.
            Empate: Todas las apuestas son ganadoras. Perdida por cualquier
            resultado: Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong
              ><strong>L&iacute;nea de Gol &frac34; (0.75)</strong></strong
            >
          </p>
          <p>
            <strong><strong>Equipo -&frac34;</strong></strong
            >&nbsp;(-0.75) goles:
          </p>
          <p>
            Gane por 2 o m&aacute;s: Todas las apuestas son ganadoras. Gane por
            exactamente 1 gol : La mitad de la apuesta gana al precio fijado. La
            otra mitad se reembolsa. Empate o perdida por cualquier resultado:
            Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Equipo +&frac34;</strong></strong
            >&nbsp;(+0.75) goles:
          </p>
          <p>
            Empate o gane por cualquier resultado: Todas las apuestas son
            ganadoras. P&eacute;rdida por exactamente 1 gol: Mitad de dinero en
            riesgo se reembolsa. La otra mitad se declara perdedora.
            P&eacute;rdida por dos o mas goles: Todas las apuestas son
            perdedoras.
          </p>
          <p>
            <strong><strong>L&iacute;nea de Gol 1.</strong></strong>
          </p>
          <p>
            <strong><strong>Equipo -1 gol:</strong></strong>
          </p>
          <p>
            Gane por dos o m&aacute;s: Todas las apuestas son ganadoras. Gane
            por exactamente un gol: Todas las apuestas son reembolsadas. Empate
            o p&eacute;rdida: Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Equipo +1 goals:</strong></strong>
          </p>
          <p>
            Gane por cualquier resultado y empate: Todas las apuestas son
            ganadoras. Perdida por exactamente 1 gol: Todas las apuestas son
            reembolsadas. Perdida por 2 o m&aacute;s goles: Todas las apuestas
            son perdedoras.
          </p>
          <p>
            <strong
              ><strong>L&iacute;nea de Gol 1 &frac14; (1.25).</strong></strong
            >
          </p>
          <p>
            <strong><strong>Equipo -1 &frac14;</strong></strong
            >&nbsp;(-1.25) goals:
          </p>
          <p>
            Gane por dos o mas goles: Todas las apuestas son ganadoras. Gane por
            exactamente 1 gol. La mitad del dinero en riesgo se reembolsa. La
            otra mitad se declara perdedora. Empate o p&eacute;rdida por
            cualquier resultado. Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Equipo +1 &frac14;</strong></strong
            >&nbsp;(+1.25) goles:
          </p>
          <p>
            Gane por cualquier resultado o empate: Todas las apuestas son
            ganadoras. P&eacute;rdida por exactamente 1 gol: La mitad de la
            apuesta gana al precio fijado. La otra mitad se reembolsa.
            P&eacute;rdida por 2 o mas goles. Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong
              ><strong>L&iacute;nea de goles 1 &frac12; (1.5).</strong></strong
            >
          </p>
          <p>
            <strong><strong>Equipo -1 &frac12;</strong></strong
            >&nbsp;(-1.5) goles:
          </p>
          <p>
            Gane por dos o mas goles: Todas las apuestas son ganadoras. Gane por
            exactamente 1 gol, empate o p&eacute;rdida por cualquier marcador:
            Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Equipo +1 &frac12;</strong></strong
            >&nbsp;(+1.5) goals:
          </p>
          <p>
            P&eacute;rdida por exactamente 1 gol, empate o gane con cualquier
            resultado: Todas las apuestas son ganadoras. P&eacute;rdida por dos
            o mas goles: Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong
              ><strong
                >L&iacute;nea de goles 1 &frac34; (1.75) goles.</strong
              ></strong
            >
          </p>
          <p>
            <strong><strong>Equipo -1 &frac34;</strong></strong
            >&nbsp;(-1.75) goles:
          </p>
          <p>
            Gane por 3 o mas goles: Todas las apuestas son ganadoras. Gane por
            exactamente 2 goles: La mitad de la apuesta gana al precio fijado.
            La otra mitad se reembolsa. Gane por un gol, empate o p&eacute;rdida
            por cualquier marcador: Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Equipo +1 &frac34;</strong></strong
            >&nbsp;(+1.75) goles:
          </p>
          <p>
            P&eacute;rdida por 1 gol , empate o gane por cualquier resultado:
            Todas las apuestas so ganadoras. P&eacute;rdida por exactamente 2
            goles Mitad de dinero en riesgo se reembolsa. La otra mitad se
            declara perdedora. P&eacute;rdida por 3 o m&aacute;s goles : Todas
            las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>L&iacute;nea de goles 2.</strong></strong>
          </p>
          <p>
            <strong><strong>Equipo -2</strong></strong
            >&nbsp;goles:
          </p>
          <p>
            Gane por 3 o m&aacute;s goles: Todas las apuestas son ganadoras.
            Gane por exactamente 2 goles: Todas las apuestas se reembolsan. Gane
            por 1 gol, empate o p&eacute;rdida por cualquier resultado: Todas
            las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Equipo +2</strong></strong
            >&nbsp;goals:
          </p>
          <p>
            P&eacute;rdida por 1 gol, empate o gane por cualquier resultado:
            Todas las apuestas son ganadoras. P&eacute;rdida por exactamente 2
            goles: Todas las apuestas se reembolsan. P&eacute;rdida por 3 o
            m&aacute;s goles: Todas las apuestas son perdedoras.
          </p>
          <p>
            <strong><strong>Totales Asian Handicap</strong></strong>
          </p>
          <p>
            <strong><strong>Abajo &frac12;</strong></strong
            >&nbsp;Con 0 goles marcados en el partido, la apuesta es ganadora;
            con 1 o m&aacute;s goles en el partido, la apuesta es perdedora.
            Arriba &frac12; Con 0 goles marcados en el partido, la apuesta es
            perdedora; con 1 o m&aacute;s goles en el partido, la apuesta es
            ganadora.
          </p>
          <p>
            <strong><strong>Abajo &frac34;</strong></strong
            >&nbsp;Con 0 goles marcados en el partido, la apuesta es ganadora;
            con 1 gol marcado en el partido, la mitad de la apuesta pierde; con
            2 o m&aacute;s goles en el partido, la apuesta es perdedora. Arriba
            &frac34; Con 0 goles marcados en el partido, la apuesta es
            perdedora; con 1 gol marcado en el partido, la mitad de la apuesta
            gana; con 2 o m&aacute;s goles en el partido, la apuesta es
            ganadora.
          </p>
          <p>
            <strong><strong>Abajo 1&frac14;</strong></strong
            >&nbsp;Con 0 goles marcados en el partido, la apuesta es ganadora;
            con 1 gol marcado en el partido, la mitad de apuesta gana; con 2 o
            m&aacute;s goles en el partido, la apuesta es perdedora. Arriba
            1&frac14; Con 0 goles marcados en el partido, la apuesta pierde; con
            1 gol marcado en el partido, la mitad de la apuesta pierde; con 2 o
            m&aacute;s goles en el partido, la apuesta es ganadora.
          </p>
          <p>
            <strong><strong>Abajo 1 &frac12;</strong></strong
            >&nbsp;Con 0 o 1 gol en el partido, la apuesta es ganadora; con 2 o
            m&aacute;s goles en el partido la apuesta es perdedora. Arriba 1
            &frac12; Con 0 o 1 gol en el partido, la apuesta es perdedora; con 2
            o m&aacute;s goles, la apuesta es ganadora.
          </p>
          <p>
            <strong><strong>Abajo 1&frac34;</strong></strong
            >&nbsp;Con 0 o 1 gol en el partido, la apuesta es ganadora; con 2
            goles en el partido, la mitad de la apuesta pierde; con 3 o
            m&aacute;s goles en el partido la apuesta es perdedora. Arriba
            1&frac34; Con 0 o 1 gol en el partido, la apuesta es perdedora; con
            2 goles en el partido la mitad de la apuesta es ganadora; con 3 o
            m&aacute;s goles, la apuesta es ganadora.
          </p>
          <p>
            <strong><strong>Abajo 2&frac14;</strong></strong
            >&nbsp;Con 0 o 1 gol en el partido, la apuesta es ganadora; con 2
            goles en el partido, la mitad de la apuesta es ganadora; con 3 o
            m&aacute;s goles en el partido, la apuesta es perdedora. Arriba
            2&frac14; Con 0 o 1 gol la apuesta es perdedora; con 2 goles en el
            partido la mitad de la apuesta pierde; con 3 o m&aacute;s goles la
            apuesta es ganadora.
          </p>
          <p>
            <strong><strong>Under 2 &frac12;</strong></strong
            >&nbsp;Con 0, 1 o 2 goles, la apuesta es ganadora; con 3 o
            m&aacute;s goles la apuesta es perdedora. Arriba 2 &frac12; Con 0, 1
            o 2 goles en el partido, la apuesta es perdedora; con 3 o m&aacute;s
            goles, la apuesta es ganadora.
          </p>
          <p>
            <strong><strong>Under 2&frac34;</strong></strong
            >&nbsp;Con 0, 1 o 2 goles, la apuesta es ganadora; con 3 goles en el
            partido la mitad de la apuesta pierde; con 4 o m&aacute;s goles la
            apuesta es perdedora. Arriba 2&frac34; Con 0, 1 o 2 goles en el
            partido, la apuesta es perdedora; con 3 goles en el partido, la
            mitad de la apuesta gana; con 4 o m&aacute;s goles, la apuesta es
            ganadora.
          </p>
          <ul>
            <li>
              Se aplican las mismas reglas para cualquier l&iacute;neas de asian
              handicap superior a -2 &frac14;, -3 &frac34;, etc.
            </li>
            <li>
              Los totales de f&uacute;tbol que utilizan l&iacute;neas de
              Handicap asi&aacute;tico tambi&eacute;n siguen los mismos
              principios de &frac14; de goles para la clasificaci&oacute;n.
            </li>
            <li>
              En caso de un parlay con l&iacute;nea de h&aacute;ndicap
              asi&aacute;tico, el pick ser&aacute; eliminado y el parlay
              revertir&aacute; al menor n&uacute;mero de equipos. Ejemplo 2 Los
              parlays de equipo se convierten en jugadas directas. 3 Equipos
              combinados se convierten en 2 Equipos combinados.
            </li>
          </ul>
          <br />
          <p>
            <strong><strong>GOLF</strong></strong>
          </p>
          <p>Lo que ofrecemos en Golf:</p>
          <ol>
            <li>
              <strong><strong>Para ganar el torneo:</strong></strong
              >Los clientes apuestan a que uno de los jugadores sea el
              campe&oacute;n del torneo.
            </li>
            <li>
              <strong><strong>Para terminar en el Top 5:</strong></strong
              >Los 5 primeros finalistas y cualquiera que empat&oacute; con el
              quinto finalista.
            </li>
            <li>
              <strong><strong>Para terminar en el Top 10:</strong></strong
              >Los 10 mejores finalistas y cualquiera que empat&oacute; con el
              d&eacute;cimo finalista.
            </li>
            <li>
              <strong><strong>Emparejamientos</strong></strong
              >: Emparejamos jugadores y el cliente elige uno de ellos para
              tener un mejor registro que el otro. Ofrecemos Emparejamientos
              para todo el torneo y en los principales eventos tambi&eacute;n
              ofrecemos apuestas para Emparejamientos de las Rondas.
            </li>
            <li>
              <strong><strong>Apuestas de 3 Bolas:</strong></strong
              >En juegos de 3 Ball Betting, 3 golfistas se enfrentan y el
              golfista con la mejor puntuaci&oacute;n gana el grupo. Las
              apuestas de 3 bolas se pueden ofrecer para todo el torneo o para
              una ronda espec&iacute;fica. Si dos o m&aacute;s jugadores
              est&aacute;n empatados por la victoria en el grupo, se
              aplicar&aacute; la regla de empate.
            </li>
            <li>
              <strong><strong>Tiros de 6 Bolas:</strong></strong
              >En juegos de 6 Ball Shooter, 6 golfistas se enfrentan y el
              golfista con la mejor puntuaci&oacute;n gana el grupo. Se puede
              ofrecer 6 Ball Shooter para todo el torneo o para la ronda
              espec&iacute;fica. Si dos o m&aacute;s jugadores est&aacute;n
              empatados en la victoria, se aplicar&aacute; la regla de empate.
            </li>
            <li>
              <strong><strong>Jugador Principal en un grupo:</strong></strong
              >En torneos importantes, ofrecemos grupos como el mejor jugador
              AMERICANO en el torneo. En tales piscinas, si se producen empates
              para la mejor posici&oacute;n, se aplicar&aacute; la regla del
              calor muerto.
            </li>
            <li>
              <strong><strong>L&iacute;der de ronda:</strong></strong
              >En torneos importantes, ofrecemos un grupo para el mejor puntaje
              en una ronda de juego espec&iacute;fica. En dichos grupos, si se
              producen empates para obtener la mejor puntuaci&oacute;n, se
              aplicar&aacute; la regla del calor muerto.
            </li>
          </ol>
          <p>
            <strong><strong>Regla Dead Heat</strong></strong>
          </p>
          <p>
            Para las apuestas de golf que contiene m&aacute;s de dos
            participantes, tales como 3 Bolas, 6 Shooter Ball mejores jugadores
            en un grupo y el l&iacute;der de Ronda (S), por ejemplo, que dos o
            m&aacute;s jugadores empatan en el mismo lugar se aplica la regla de
            empate de la siguiente manera: La apuesta se reduce de acuerdo con
            la cantidad de jugadores que empatan y luego se aplica a las cuotas
            completas con el resto de la apuesta considerada como perdida.
          </p>
          <p>Por ejemplo:</p>
          <ol>
            <li>
              Apuesta $ 150 con una probabilidad de +200 y 2 jugadores empatan
              en la misma posici&oacute;n. Se pierde la mitad de la apuesta: $
              75, la otra mitad gana y se le pagan las mismas probabilidades.
              Entonces $ 75 gana a +200 = $ 150 &ndash; $ 75 = $ 75 netos.
            </li>
            <li>
              Si 3 jugadores empatan en la misma posici&oacute;n, entonces: dos
              tercios de la apuesta se pierden &ndash; $ 100, los terceros $50
              restantes ganan a +200.
            </li>
          </ol>
          <p>
            Tenga en cuenta que esta regla no se aplica a los enfrentamientos de
            dos jugadores donde un empate resulta en un empate.
          </p>
          <p>
            Para los Emparejamientos, el jugador debe dar el primer golpe para
            considerarse v&aacute;lido. Si un jugador no da el primer golpe,
            entonces cualquier apuesta que lo involucre se considerar&aacute;
            inv&aacute;lida y ser&aacute; cancelada. La excepci&oacute;n a esta
            regla es cuando la apuesta se realiza en una quiniela y todos los
            jugadores tienen acci&oacute;n independientemente de si comienzan o
            no el torneo. (por ejemplo: para ganar EL TORNEO)
          </p>
          <p>
            Si un jugador se da un primer golpe, es v&aacute;lido
            independientemente del n&uacute;mero de hoyos jugados.
          </p>
          <table>
            <tbody>
              <tr>
                <td width="311">
                  <p>Fred Funk</p>
                </td>
                <td width="311">
                  <p>- 125</p>
                </td>
              </tr>
              <tr>
                <td width="311">
                  <p>Joe Durant</p>
                </td>
                <td width="311">
                  <p>+105</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            En este torneo, si el cliente elige Fred Funk, la apuesta
            est&aacute; en Fred Funk para tener un mejor registro que Joe
            Durant. No necesariamente que Funk debe ganar el campeonato, sino
            que va a terminar en una mejor posici&oacute;n que Durant.
          </p>
          <p>
            Ambos jugadores deben pegarle a la bola en el torneo para que la
            apuesta sea v&aacute;lida. En una apuesta de dos jugadores, el
            jugador que complete m&aacute;s hoyos ser&aacute; declarado ganador.
            Si ambos jugadores completan el mismo n&uacute;mero de hoyos, la
            puntuaci&oacute;n m&aacute;s baja gana.
          </p>
          <p>
            <strong><strong>Rondas Espec&iacute;ficas:</strong></strong
            >&nbsp;Aparte de las probabilidades de ganar todo el Torneo,
            tambi&eacute;n podemos ofrecer cuotas para ganar una Ronda
            Espec&iacute;fica. As&iacute; que un cliente puede apostar a un
            jugador para ganar la 3a ronda solamente. Si este es el caso,
            especificaremos que la apuesta es solo para la ronda y no para todo
            el torneo. Ambos jugadores deben salir en la ronda especificada para
            que la apuesta sea v&aacute;lida. La 4a ronda no incluye juegos
            post-temporada
          </p>
          <p>
            Cuando se utiliza un m&eacute;todo de puntuaci&oacute;n de puntos
            como Stableford, el n&uacute;mero m&aacute;s alto de puntos gana. La
            &uacute;nica excepci&oacute;n a esta regla es cuando un torneo se
            acorta por lluvia y las puntuaciones finales son determinadas por la
            PGA por lo general volviendo a la &uacute;ltima ronda completada.
          </p>
          <p>
            SOLO aceptamos Apuestas Directas para Golf, independientemente de si
            se trata de una apuesta en Para Ganar Torneos, Top 5, Top 10,
            Emparejamientos de Torneo, Emparejamientos de Rondas o Apuestas de
            Jugador Principal o L&iacute;der. Cualquier otro tipo de apuesta que
            no sea una Apuesta Directa (Ejemplo: Combinadas (Parlays),
            Condicionadas, Reversas y/o Round Robins) ser&aacute; cancelada por
            nosotros sin previo aviso.
          </p>
          <p>
            <strong><strong>BALONMANO</strong></strong>
          </p>
          <p>
            A menos que se indique lo contrario, todas las apuestas se
            resolver&aacute;n en funci&oacute;n de la puntuaci&oacute;n al final
            del tiempo reglamentario y excluyendo el tiempo extra si se juega.
            Todos los resultados de apuestas de partidos se basan en el
            resultado al final de un juego programado de 60 minutos, a menos que
            se indique lo contrario.
          </p>
          <p>
            Si los 60 minutos programados no se juegan, las apuestas se
            anular&aacute;n, con la excepci&oacute;n de los siguientes
            incluir&aacute;n tiempo extra / penales para prop&oacute;sito de
            calificaci&oacute;n:
          </p>
          <ul>
            <li>Para Calificar</li>
            <li>Para levantar trofeo</li>
          </ul>
          <p>
            Espec&iacute;ficamente para cualquier competencia que use una Regla
            de la Misericordia, en caso de que dicha Regla se convoque en un
            partido, todas las apuestas se mantendr&aacute;n en el marcador en
            ese momento.
          </p>
          <p>
            Si se cambia el lugar de un partido, las apuestas ya realizadas se
            mantendr&aacute;n siempre que el equipo local a&uacute;n est&eacute;
            designado como tal.
          </p>
          <p>
            Si el equipo local y el visitante para un partido en la lista juegan
            el partido en el lugar del equipo visitante, las apuestas se
            mantendr&aacute;n siempre que el equipo local todav&iacute;a
            est&eacute; oficialmente designado como tal, de lo contrario las
            apuestas ser&aacute;n anuladas.
          </p>
          <p>
            <strong><strong>BOXEO/ MMA / UFC</strong></strong>
          </p>
          <p>Para Boxeo hay tres tipos de apuestas:</p>
          <ol>
            <li>
              <strong><strong>Para Ganar la Pelea:</strong></strong
              >Elegir el luchador que el cliente cree que ganar&aacute; la
              pelea. Los empates cancelar&aacute;n estas apuestas.
            </li>
            <li>
              <strong><strong>Total de Rondas:</strong></strong
              >El cliente apuesta las Altas o Bajas sobre el n&uacute;mero de
              rondas en las que se terminar&aacute; la pelea.
            </li>
            <li>
              <strong><strong>Apuestas de Resultado Correcto:</strong></strong
              >Los jugadores pueden apostar en qu&eacute; boxeador ganar&aacute;
              la pelea y la forma en que se gana la pelea. Un NOCAUT (KNOCKOUT)
              incluye Nocauts T&eacute;cnicos (TKO) y Descalificaciones (DQ). Se
              determina que una DECISI&Oacute;N es cada vez que se utilizan los
              cuadros de mando del juez para determinar el ganador de la pelea,
              independientemente de la ronda en la que esto ocurra. EMPATE
              (DRAW) es una opci&oacute;n de apuesta y si se produce un EMPATE,
              las otras apuestas de Resultado Correcto ser&aacute;n perdedoras.
            </li>
          </ol>
          <p>
            Digamos que la l&iacute;nea en las rondas en una pelea es de 4.5 (3
            minutos). Si un jugador apuesta en las Altas, est&aacute; diciendo
            que la pelea durar&aacute; m&aacute;s de 4.5 rondas. Eso
            ser&iacute;a 4 rondas completas y los primeros 1.5 minutos en la 5a
            ronda (1 minuto y 30 segundos).
          </p>
          <p>
            <strong><strong>TENNIS</strong></strong>
          </p>
          <p>Hay tres formas que ofrecemos para las apuestas de Tenis:</p>
          <ol>
            <li>
              <strong><strong>Para Ganar el Torneo:</strong></strong
              >Los clientes apuestan a que uno de los jugadores sea el
              campe&oacute;n del torneo.
            </li>
            <li>
              <strong><strong> Ganar el Partido:</strong></strong
              >Elegir el jugador que el cliente cree que ganar&aacute; el
              partido. El partido es oficial si se sirve una pelota.
              Despu&eacute;s de eso, si un jugador se retira ser&aacute;
              declarado el perdedor sin importar la puntuaci&oacute;n en ese
              momento.
            </li>
            <li>
              <strong><strong> Proposiciones:</strong></strong
              >Jugador para ganar el 1er set, N&uacute;mero total de juegos o
              sets, etc.
            </li>
          </ol>
          <p>
            <strong><strong>Ganar el 1er set</strong></strong
            >&nbsp;(to win set, 1st set props, etc): El primer set debe
            completarse para que todas las apuestas en el primer set tengan
            acci&oacute;n. Si despu&eacute;s de que se completa el primer set,
            el partido se cancela por alg&uacute;n motivo, todas las apuestas
            del primer set se considerar&aacute;n v&aacute;lidas.
          </p>
          <p>
            <strong><strong>Props de emparejamiento</strong></strong
            >&nbsp;(Juegos o Sets, puestas de Resultados Exactos, etc.): el
            partido debe completarse para que las apuestas tengan acci&oacute;n,
            si uno de los jugadores se retira o por otras causas el partido no
            ha terminado, todas las apuestas se considerar&aacute;n
            INV&Aacute;LIDAS.
          </p>
          <p>
            Todas las apuestas de tenis son buenas incluso si la fecha del
            partido y la hora cambian debido al clima o a cualquier otra causa.
          </p>
          <p>
            Solo aceptamos apuestas directas en los partidos, proposiciones y
            futuras de tenis. Cualquier otra apuesta como Combinadas (Parlays),
            Condicionadas, Reversas y/o Round Robins ser&aacute; cancelada por
            nosotros.
          </p>
          <p>
            En caso de que un partido en progreso se retrase debido al clima u
            otras razones, la apuesta tendr&aacute; efecto hasta que se decida
            un ganador. Nota: El Sistema Autom&aacute;tico de Apuestas en Vivo
            tiene su propio conjunto de reglas. Todos est&aacute;n disponibles
            dentro del sistema de apuestas en vivo.
          </p>
          <p>
            En el caso de que un partido se decida por un S&uacute;per desempate
            (en lugar de un set completo), todas las apuestas relacionadas con
            JUEGOS se anular&aacute;n.
          </p>
          <p>
            Si un partido se decide de esta manera, el desempate del partido se
            considerar&aacute; como el tercer set y, a efectos de las apuestas,
            el ganador obtendr&aacute; una victoria de 2-1 sets.
          </p>
          <p>
            <strong><strong>AUTORACING</strong></strong>
          </p>
          <p>
            Ofreceremos QUINIELAS FUTURAS para carreras NASCAR, NATIONWIDE, INDY
            y F1. En estas QUINIELAS FUTURAS todas las apuestas se consideran
            V&Aacute;LIDAS incluso si el piloto no califica para la carrera
            principal.
          </p>
          <p>
            Despu&eacute;s de las rondas clasificatorias, ofreceremos cuotas
            para ganar la carrera, terminar en el Top 3, Top 5 o Top 10,
            S&iacute;/NO en esta posici&oacute;n final, Partidos de Carrera y
            Apuestas de Grupo. Para todo este tipo de apuestas el piloto DEBE
            comenzar en la carrera principal para que esta sea v&aacute;lida.
          </p>
          <p>
            En las Apuestas de Grupo si dos o m&aacute;s jugadores empatan por
            el mejor lugar en la Quiniela, los precios de las apuestas se
            dividir&aacute;n por el n&uacute;mero de los jugadores ganadores.
          </p>
          <p>
            Solo aceptamos Apuestas Directas en Carreras de
            Automovil&iacute;sticas y las Proposiciones y Futuras de este.
            Cualquier otra apuesta como Combinada (Parlay), Condicionada,
            Reversa y/o Round Robin ser&aacute; cancelada por nosotros.
          </p>
          <p>
            Las descalificaciones posteriores a la carrera no afectar&aacute;n o
            cambiar&aacute;n el resultado de las apuestas.
          </p>
          <p>
            <strong><strong>PROPOSICIONES GENERALES</strong></strong>
          </p>
          <p>
            F&uacute;tbol, Baloncesto, Hockey y TODOS los otros equipos
            deportivos y proposiciones para jugadores
          </p>
          <ol>
            <li>
              Proposiciones de jugadores cara a cara, los dos jugadores tienen
              que jugar el partido para que la apuesta sea v&aacute;lida.
            </li>
            <li>
              En las proposiciones que involucran a varios jugadores de cada
              equipo, todos los jugadores son v&aacute;lidos a menos que se
              especifique lo contrario en la apuesta.
            </li>
            <li>
              En eventos de Quinielas, todos los jugadores son v&aacute;lidos.
              Si dos o m&aacute;s jugadores empatan por el mejor lugar en la
              quinela, los precios de las apuestas se dividir&aacute;n por el
              n&uacute;mero de jugadores ganadores
            </li>
            <li>
              Las proposiciones de Equipo y Jugador se pueden utilizar para
              apuestas Directas, Combinadas, Condicionadas y/o Round Robins
              (TEASERS NO) siempre y cuando el otro equipo en dichas apuestas no
              pertenezca a la misma familia. Cualquier apuesta dentro de la
              misma familia (O PROPS UTILIZADO EN TEASERS) ser&aacute; cancelada
              por nosotros sin previo aviso.
            </li>
            <li>
              Proposiciones de jugadores defensivos, todas las apuestas
              realizadas en TOTAL de BLOQUEOS (TACKLES) ASISTIDOS incluyen el
              sistema defensivo total que el jugador acumula, incluyendo los que
              juega en equipos especiales tambi&eacute;n. Los Sacks (bloqueo de
              mariscal de campo) no cuentan para esta apuesta.
            </li>
            <li>
              Para los totales de cada jugador en f&uacute;tbol y baloncesto, el
              jugador debe jugar en el partido para que la apuesta sea
              v&aacute;lida.
            </li>
            <li>
              Para el F&uacute;tbol Americano, un &lsquo;MUFFED PUNT&rsquo;
              (tocar la pelota antes de poseerla) recuperado por el equipo que
              patea ser&aacute; considerado un &lsquo;fumble&rsquo; (un
              bal&oacute;n suelto o pifia).
            </li>
            <li>
              Para los puntajes de 1er intento de FG de baloncesto, los tiros
              libres no se consideran un intento de gol de campo, por lo tanto,
              si un jugador recibe una falta en la 1ra jugada del juego y hace
              el 1er tiro libre, el puntal continuar&aacute; hasta que se
              intente un gol de campo.
            </li>
          </ol>
          <br />
          <p>
            <strong><strong>LIVE IN-GAME</strong></strong>
          </p>
          <p>
            Las L&iacute;neas de Partidos en Vivo solo est&aacute;n disponibles
            para Apuestas Directas y &uacute;nicamente por Internet. Todas las
            apuestas tienen en cuenta la puntuaci&oacute;n del partido completo,
            evento o juego (a menos que se especifique lo contrario).
          </p>
          <p>
            Las L&iacute;neas de Partidos en Vivo se ofrecer&aacute;n en eventos
            importantes y las reglas regulares para el deporte se aplican a
            dichas l&iacute;neas. Nos reservamos el derecho de quitar las
            L&iacute;neas de Partidos en Vivo en cualquier momento del juego.
          </p>
          <p>
            Las L&iacute;neas de Partidos en Vivo no pueden ser canceladas por
            el jugador.
          </p>
          <p>
            Nos reservamos el derecho de cancelar cualquier L&iacute;nea de
            Partidos en Vivo por cualquier motivo, incluidas las l&iacute;neas
            incorrectas obvias.
          </p>
          <p>
            Nota: El Sistema de Apuestas Autom&aacute;ticas En Vivo tiene su
            propio conjunto de reglas. Todos est&aacute;n disponibles dentro del
            Sistema de L&iacute;neas de Partidos en Vivo.
          </p>
          <p>
            Para obtener m&aacute;s informaci&oacute;n, p&oacute;ngase en
            contacto con nuestro departamento de apuestas o servicio al cliente.
          </p>
          <p>
            <strong><strong>PARLAY CARDS</strong></strong>
          </p>
          <p>
            En el caso de un juego suspendido o cancelado, la Tarjeta Parlay
            tendr&aacute; acci&oacute;n y el juego en cuesti&oacute;n
            ser&aacute; retirado de la Tarjeta (Ejemplo: una Tarjeta Parlay de 5
            equipos se convertir&aacute; en una Tarjeta Parlay de 4 equipos.) En
            una Tarjeta Parlay de 3 equipos si uno de los juegos se suspende o
            cancela la Tarjeta Parlay no tendr&aacute; acci&oacute;n y los
            fondos ser&aacute;n reembolsados al cliente.
          </p>
          <p>
            <strong><strong>Rugby </strong></strong>
          </p>
          <p>
            A menos que se indique lo contrario, todas las apuestas de Rugby se
            califican en 80 minutos de juego, lo que incluye cualquier tiempo de
            detenci&oacute;n, y no incluye tiempo adicional a menos que se
            especifique.
          </p>
          <p>
            Cuando se ofrece una opci&oacute;n de empate, la apuesta se califica
            al final de la regulaci&oacute;n, no se incluye tiempo adicional.
          </p>
          <p>
            Si se cambia el lugar anunciado para el partido, todas las apuestas
            en ese partido se anular&aacute;n.
          </p>
          <p>
            En el caso de cambio de oponente del que estaba anunciado, todas las
            apuestas para ese partido ser&aacute;n anuladas.
          </p>
          <p>
            <strong><strong>Eventos Abandonados:</strong></strong
            >&nbsp;Todas las apuestas se anulan aparte de los mercados en los
            que el resultado ya est&aacute; determinado.
          </p>
          <p>
            <strong><strong>Eventos pospuestos:</strong></strong
            >&nbsp;son anulados a menos que se reorganicen y se jueguen en la
            misma Semana de Rugby (lunes a domingo, hora del Reino Unido
            incluida).
          </p>
          <p>El equipo local es el de arriba</p>
          <p>
            <strong><strong>REGLAS AUSTRALIANAS </strong></strong>
          </p>
          <p>
            <strong><strong>Reglas Generales</strong></strong>
          </p>
          <p>
            Todos los mercados de partidos se calificar&aacute;n, incluida la
            pr&oacute;rroga si se juega, a menos que se indique lo contrario. El
            tiempo reglamentario debe completarse para que las apuestas sean
            v&aacute;lidas a menos que se indique lo contrario. Si la
            duraci&oacute;n de un partido es cambiada por el organismo rector
            antes del comienzo del juego, la duraci&oacute;n revisada del juego
            se considerar&aacute; como el tiempo oficial de regulaci&oacute;n
            para este partido, y todas las apuestas se mantendr&aacute;n
            mientras se complete este nuevo tiempo reglamentario.
          </p>
          <p>
            <strong><strong>Apuestas de Partido</strong></strong
            >&nbsp;Si alg&uacute;n partido termina en empate, incluyendo la
            pr&oacute;rroga si se juega, las apuestas ser&aacute;n reembolsadas
            a menos que se ofrezca un precio para el empate. Las apuestas se
            calificar&aacute;n solo en el resultado oficial de la AFL.
          </p>
          <p>
            <strong><strong>Partidos Programados no Jugados</strong></strong
            >&nbsp;Si se cambia el lugar del partido, las apuestas ya realizadas
            se mantendr&aacute;n siempre que el equipo local siga siendo
            designado como tal. Si el equipo local y el equipo visitante de un
            partido de la lista se invierten, las apuestas realizadas en
            funci&oacute;n del anuncio original se anular&aacute;n.
          </p>
          <p>El equipo local es el de arriba</p>
          <p>
            <strong><strong>CRICKET</strong></strong>
          </p>
          <p>
            <strong><strong>Incluyendo Veinte/20 General:</strong></strong>
          </p>
          <ol>
            <li>
              Todas las apuestas se liquidar&aacute;n utilizando el resultado
              oficial declarado por el organismo rector correspondiente del
              partido o competici&oacute;n en cuesti&oacute;n.
            </li>
            <li>
              Cambio de lugar Si se cambia el lugar del partido, las apuestas ya
              realizadas se mantendr&aacute;n siempre que el equipo local siga
              siendo designado como tal. Si el equipo local y el equipo
              visitante de un partido de la lista se invierten, las apuestas
              realizadas en funci&oacute;n del anuncio original se
              anular&aacute;n
            </li>
            <li>
              Si un partido se abandona debido a interferencias externas, las
              apuestas sobre el resultado para el total del partido se
              anular&aacute;n, si no se declara ning&uacute;n ganador desde el
              sitio oficial, todas las apuestas al ganador del partido se
              anular&aacute;n.
            </li>
            <li>
              Si un partido se ve afectado por factores externos (como el mal
              tiempo), liquidaremos su apuesta en funci&oacute;n de la regla
              oficial de la competici&oacute;n (esto incluye los partidos
              afectados por un c&aacute;lculo matem&aacute;tico como el
              m&eacute;todo Duckworth-Lewis (DL) o el sistema Jayadevan
              (VJD)*).* M&eacute;todo Duckworth Lewis / sistema Jayadevan. Estos
              son sistemas utilizados para ajustar las puntuaciones en caso de
              un retraso de lluvia durante los partidos de un d&iacute;a, con el
              fin de dejar el balance del partido inalterado. Los sistemas
              utilizan el n&uacute;mero de &lsquo;overs&rsquo; que cada equipo
              a&uacute;n tiene que recibir y el n&uacute;mero de
              &lsquo;wickets&rsquo; que tienen en la mano para alcanzar un
              resultado oficial. 5.
            </li>
            <li>
              Cuando no se cotiza ning&uacute;n precio para el empate y las
              reglas oficiales de la competencia no determinan un ganador, todas
              las apuestas se calificar&aacute;n canceladas. En las
              competiciones en las que un bowl out o super over determina un
              ganador, las apuestas se establecer&aacute;n en funci&oacute;n del
              resultado oficial.
            </li>
          </ol>
          <p>
            <strong><strong>Series: </strong></strong>Si se empata una serie y
            no se ofreci&oacute; ninguna opci&oacute;n de empate, todas las
            apuestas se considerar&aacute;n como empate y se
            reembolsar&aacute;n.
          </p>
          <p>
            <strong><strong>Partidos de Prueba:</strong></strong
            >&nbsp;
          </p>
          <ol>
            <li>
              En caso de que el juego no haya dado lugar al menos a 4 entradas,
              todas las apuestas sobre el resultado para los totales (altas /
              bajas) ser&aacute;n anuladas.
            </li>
            <li>
              En caso de que el juego no dure al menos 4 entradas, todas las
              apuestas sobre el resultado del ganador del partido se liquidan
              seg&uacute;n el anuncio oficial.
            </li>
            <li>
              <strong><strong>Prueba de coincidencia empatada:</strong></strong
              >En el caso de un partido de prueba empatado (es decir, donde se
              hayan completado todas las entradas y ambos equipos tengan el
              mismo puntaje), todas las apuestas en el juego ser&aacute;n
              anuladas.
            </li>
            <li>
              Si se ofrece una l&iacute;nea de dinero de tres v&iacute;as con el
              sorteo como tercera opci&oacute;n de apuesta, solo los apostadores
              que apostaron en el sorteo ser&aacute;n pagados como ganadores;
              Aquellos que apostaron a ganar a cualquiera de los equipos o
              concursantes perder&aacute;n su apuesta.
            </li>
            <li>
              Si un partido de prueba es abandonado o interrumpido por
              interferencia externa (disturbios, etc.), todas las apuestas en
              este partido ser&aacute;n anuladas.
            </li>
          </ol>
          <br />
          <p>
            <strong><strong>Internacionales de un d&iacute;a:</strong></strong>
          </p>
          <ol>
            <li>
              En caso de que el juego no haya superado al menos el n&uacute;mero
              oficial de mayores, se anular&aacute;n todas las apuestas sobre el
              resultado del mayor / menor.
            </li>
            <li>
              En caso de que el juego no haya tenido al menos el n&uacute;mero
              oficial de mayores, todas las apuestas sobre el resultado del
              ganador del partido se liquidan seg&uacute;n el anuncio oficial.
            </li>
            <li>
              En los partidos en los que se utiliza un d&iacute;a de reserva,
              todas las apuestas se mantendr&aacute;n y se transferir&aacute;n
              al d&iacute;a de reserva.
            </li>
          </ol>
          <br />
          <p>
            <strong><strong>Veinte / 20</strong></strong>
          </p>
          <ol>
            <li>
              En caso de que el juego no haya tenido al menos el n&uacute;mero
              oficial de overs, se anular&aacute;n todas las apuestas sobre el
              resultado del over / under.
            </li>
            <li>
              En caso de que el juego no haya tenido al menos el n&uacute;mero
              oficial de overs, todas las apuestas sobre el resultado del
              ganador del partido se liquidan seg&uacute;n el anuncio oficial.
            </li>
          </ol>
          <br />
          <p>
            <strong><strong>REGLAS OLIMPICAS</strong></strong>
          </p>
          <ol>
            <li>
              Para las apuestas de futuros de m&uacute;ltiples v&iacute;as,
              todos los eventos son TODO ACCI&Oacute;N, sin reembolsos.
            </li>
            <li>
              Si un atleta / equipo en particular tiene un precio de s&iacute; /
              no (bidireccional) para ganar la medalla de oro, entonces ese
              atleta / equipo debe comenzar la competencia para la
              acci&oacute;n.
            </li>
            <li>
              Para las apuestas Match-Up entre dos atletas / 2 pa&iacute;ses en
              cualquier evento, ambos atletas / pa&iacute;ses deben comenzar el
              evento para la acci&oacute;n.
            </li>
            <li>
              Para m&aacute;s / menos en el total de medallas de un pa&iacute;s
              dentro de un deporte, el total es para las competiciones
              masculinas y femeninas dentro de esa &aacute;rea &ndash; Ejemplo:
              si EE. UU. Gana 10 medallas en atletismo masculino y 9 en
              atletismo femenino, el total de medallas ganadas por EE. UU. tiene
              19 a&ntilde;os en Atletismo.
            </li>
            <li>
              Los eventos son oficiales despu&eacute;s de la ceremonia de
              entrega de medallas original. Cualquier cambio posterior a esos
              resultados no cuenta.
            </li>
            <li>
              Para los accesorios de atletas individuales, el competidor debe
              comenzar el primer evento en el que est&aacute;n programados para
              participar para la acci&oacute;n.
            </li>
          </ol>
          <br />
          <p>
            <strong><strong>REGLAS E-SPORTS </strong></strong>
          </p>
          <ol>
            <li>
              Las fechas y horas de inicio que se muestran en nuestro sitio web
              para los partidos de E-Sport son solo una indicaci&oacute;n y no
              se garantiza que sean correctas. Si un partido se suspende o
              pospone, y no se reanuda dentro de las 12 horas posteriores a la
              hora de inicio real programada, las apuestas en el partido no
              tendr&aacute;n efecto y ser&aacute;n reembolsadas. Con la
              excepci&oacute;n de cualquier apuesta sobre si un equipo/jugador
              avanza en un torneo o gana el torneo, tendr&aacute; acci&oacute;n
              independientemente de un partido suspendido o pospuesto.
            </li>
            <li>
              Si el nombre de un jugador/equipo est&aacute; mal escrito, todas
              las apuestas se mantendr&aacute;n a menos que sea obvio que es el
              objeto incorrecto.
            </li>
            <li>
              Si en un partido oficial un jugador juega con un apodo incorrecto
              o con una cuenta de pitufo, el resultado sigue siendo
              v&aacute;lido a menos que sea evidente que no es el jugador que se
              supon&iacute;a que jugar&iacute;a ese partido.
            </li>
            <li>
              Todas las apuestas se liquidar&aacute;n utilizando el resultado
              oficial declarado por el organismo rector pertinente de la
              competici&oacute;n en cuesti&oacute;n.
            </li>
            <li>
              Si no se ha habilitado una opci&oacute;n de empate, se
              contar&aacute; el tiempo extra, si se juega.
            </li>
            <li>
              Apuestas con h&aacute;ndicap: un margen en E-Sports puede ser
              Rondas/Mapas u otras medidas de conteo dependiendo del juego. El
              diferencial solo se denominar&aacute; diferencial. (Por ejemplo,
              en Counter Strike la distribuci&oacute;n ser&aacute;n rondas
              ganadas, mientras que en Starcraft 2 la distribuci&oacute;n
              ser&aacute;n mapas).
            </li>
          </ol>
          <p>
            El h&aacute;ndicap es una forma de hacer que una competici&oacute;n
            deportiva sea m&aacute;s igualada y, por tanto, m&aacute;s
            interesante como objeto de apuesta. En las apuestas de deportes
            electr&oacute;nicos, esto se hace otorgando a uno de los
            equipos/jugadores, el perdedor, algunos mapas/rondas por delante.
          </p>
          <p>Por ejemplo:</p>
          <table>
            <tbody>
              <tr>
                <td width="207">&nbsp;</td>
                <td width="207">
                  <p>Handicap</p>
                </td>
                <td width="207">
                  <p>Probabilidad</p>
                </td>
              </tr>
              <tr>
                <td width="207">
                  <p>Juegador A</p>
                </td>
                <td width="207">
                  <p>-1.5</p>
                </td>
                <td width="207">
                  <p>2.00</p>
                </td>
              </tr>
              <tr>
                <td width="207">
                  <p>Jugador B</p>
                </td>
                <td width="207">
                  <p>+1.5</p>
                </td>
                <td width="207">
                  <p>1.85</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Si el jugador A gana el partido por dos mapas o m&aacute;s, los
            apostadores del jugador A ganan y los del jugador B pierden. Si el
            jugador A gana por exactamente un mapa o el jugador B gana, los
            apostadores del jugador B ganan y los apostadores del jugador A
            pierden.
          </p>
          <ol start="7">
            <li>
              Apuestas Totales: Un total en E-sports puede ser Rondas/Mapas u
              otras medidas de conteo dependiendo del juego. El total solo se
              denominar&aacute; total.
            </li>
          </ol>
          <p>Ejemplo de un partido SC2 al mejor de tres:</p>
          <table>
            <tbody>
              <tr>
                <td width="207">
                  <p>Arriba</p>
                </td>
                <td width="207">
                  <p>2.5</p>
                </td>
                <td width="207">
                  <p>1.93</p>
                </td>
              </tr>
              <tr>
                <td width="207">
                  <p>Abajo</p>
                </td>
                <td width="207">
                  <p>2.5</p>
                </td>
                <td width="207">
                  <p>1.93</p>
                </td>
              </tr>
            </tbody>
          </table>
          <ol start="8">
            <li>
              Si cualquiera de los jugadores gana 2-0, todas las apuestas por
              menos de 2,5 ganar&aacute;n, mientras que las apuestas por
              m&aacute;s perder&aacute;n. Si cualquiera de los jugadores gana
              2-1, todas las apuestas al m&aacute;s ganan, mientras que las
              apuestas al menos pierden.
            </li>
            <li>
              Si un mapa no se completa debido a la retirada o
              descalificaci&oacute;n de un jugador, todas las apuestas sobre el
              resultado tendr&aacute;n acci&oacute;n y se validar&aacute;n en
              funci&oacute;n del ganador oficial declarado. Los mapas de
              Counter-Strike son excepciones a esta regla, por lo que todas las
              apuestas de l&iacute;nea de dinero, margen y total se cancelan si
              un equipo se retira o es descalificado antes de que se jueguen
              todas las rondas de mapa programadas.
            </li>
            <li>
              Si se cambia el n&uacute;mero anunciado de mapas/rondas, se
              cancelan todas las apuestas sobre el h&aacute;ndicap o el total.
              Las apuestas en la l&iacute;nea de dinero (resultado del partido)
              tienen acci&oacute;n.
            </li>
            <li>
              Si un jugador o equipo recibe un walkover o gana por
              decisi&oacute;n del administrador en al menos un mapa, todas las
              apuestas en la serie (l&iacute;nea de dinero, margen y total) se
              cancelar&aacute;n y se reembolsar&aacute; el dinero. En CS: GO, si
              un equipo se retira o es descalificado antes de que se jueguen
              todas las rondas programadas en un mapa, todas las apuestas en la
              serie (l&iacute;neas de dinero, margen y total) se
              cancelar&aacute;n y tambi&eacute;n se reembolsar&aacute; el
              dinero.
            </li>
          </ol>
          <br />
          <p>
            <strong><strong>VOLLEYBALL RULES</strong></strong>
          </p>
          <ol>
            <li>
              Si un partido se suspende antes de que se alcance el tiempo
              completo y no se completa el mismo d&iacute;a, las apuestas sobre
              el resultado del partido se considerar&aacute;n canceladas y se
              reembolsar&aacute;n todas las apuestas.
            </li>
            <li>
              Para las apuestas de partidos y sets, la cantidad real de puntos
              jugados en un set no afectar&aacute; la calificaci&oacute;n de las
              apuestas. Por ejemplo, los sets jugados a 21 o 25 puntos se
              califican seg&uacute;n el ganador del set.
            </li>
            <li>
              En el caso de un formato de "Local y Visitante", las apuestas en
              el partido de Visitante no incluyen el "Set de Oro".
            </li>
          </ol>
          <br />
          <p>
            <strong><strong>WATER POLO</strong></strong>
          </p>
          <ol>
            <li>
              <strong><strong>L&iacute;neas de dinero:</strong></strong
              >a menos que se indique lo contrario, todas las apuestas son
              &uacute;nicamente para el tiempo reglamentario y no incluyen
              tiempos extra ni tiros de penales.
            </li>
            <li>
              <strong
                ><strong
                  >El ganador del 1er, 2nd, 3er y 4to cuarto:</strong
                ></strong
              >&uacute;nicamente los goles anotados durante el tiempo nominado
              para el periodo cuentan. A menos que se indique lo contrario, los
              goles anotados en tiempo extra NO cuentan como parte del 4to
              periodo.
            </li>
          </ol>
          <p>
            En el caso de un formato de "Local y Visitante", las apuestas en el
            partido de Visitante no incluyen el "Set de Oro".
          </p>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.p {
  background-color: #fff;
}
.tabl {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  padding: 5px;
  background: white;
  color: black;
  border-radius: 4px !important;
  width: 100%;
}
.tabd {
  border: 1px solid #c0c0c0;
  border-collapse: collapse;
  padding: 10px;
  background: white;
  color: black;
  border-radius: 4px !important;
  width: 100%;
}
.tabl th {
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 4px !important;
  font-size: 12px !important;
}
.tabl td {
  border: 1px solid #c0c0c0;
  padding: 5px;
  border-radius: 4px !important;
}
</style>
